// Using WeakMap to keep cache items private http://2ality.com/2016/01/private-data-classes.html#keeping-private-data-in-weakmaps
const itemsStorage = new WeakMap();

/**
 * Simple in memory cache module
 */
export class Cache {
  constructor() {
    // internal cache entries storage
    itemsStorage.set(this, new Map());
  }
  /**
   * Check if a key is in the cache, without deleting it for being stale.
   */
  has(key) {
    const items = itemsStorage.get(this);
    return items.has(key);
  }
  /**
   * Returns the cache value associated to the key, or undefined if there is none or expired.
   */
  get(key) {
    const items = itemsStorage.get(this);
    if (!items.has(key)) return undefined;
    const entry = items.get(key);
    if (entry.expires && entry.expires < Date.now()) {
      // if cache entry is expired remove it from the cache
      items.delete(key);
      return undefined;
    }
    return entry.value;
  }
  /**
   * Sets the value for the key in the cache for the defined maxAge (forever by default)
   */
  set(key, value, maxAge) {
    const items = itemsStorage.get(this);
    items.set(key, {
      value,
      expires: maxAge ? Date.now() + maxAge : null, // if maxAge is falsy (0, null or underfined) simply set expires to null
    });
  }
  /**
   * Deletes the cache value by the key
   */
  delete(key) {
    const items = itemsStorage.get(this);
    items.delete(key);
  }
  /**
   * Removes all key/value pairs from the cache
   */
  clear() {
    const items = itemsStorage.get(this);
    items.clear();
  }
  /**
   * Return an array of the keys in the cache.
   */
  keys() {
    const items = itemsStorage.get(this);
    return items.keys();
  }
  /**
   * Return an array of the values in the cache.
   */
  values() {
    const items = itemsStorage.get(this);
    return items.values();
  }
  /**
   * Returns the number of key/value pairs in the cache
   */
  get size() {
    const items = itemsStorage.get(this);
    return items.size;
  }
}
