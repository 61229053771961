/**
 * This is a $partial supported vehicle.js with unnecessary context.resolves removed.
 * Please consider migrating your components to use this
 */
import PropTypes from 'prop-types';
import {
  reduce,
  set,
  get,
  pick,
  every,
  sortBy,
  isArray,
  findKey,
  findLast,
  forEach,
  partial,
  flow,
  filter,
  map,
  toArray,
} from 'lodash';
import gql from 'graphql-tag';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { HTTP_NOT_FOUND } from 'client/utils/http-status';
import { ELECTRIC_FUEL_TYPE } from 'site-modules/shared/constants/features-specs';
import { isUnknownZip } from 'site-modules/shared/utils/location';
import { isNewState, isDisabledState } from 'site-modules/shared/utils/publication-states';
import { sortByName } from 'site-modules/shared/utils/sorting';
import { getLatestYears } from 'site-modules/shared/utils/date-utils';
import { filterDiscontinuedStyles } from 'site-modules/shared/utils/vehicle-utils';
import { getQueryTypeStringModelYears } from 'site-modules/shared/utils/query-type-mapping';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { PUB_STATES } from 'client/constants/pub-states';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { getDefaultYearUrl } from 'client/data/utils/default-year-core-4062';
import { formatAllTmvBandsUrl } from 'client/data/utils/format-urls';
import { filterFleetTrims } from 'site-modules/shared/utils/core-page/fleet-trims';

import { featuresMap } from './car-features-map';
import { VisitorModel } from './visitor';
import { VehicleDefaultModel } from './vehicle-v2-default';

const DEFAULT = 'default';

const Prices = PropTypes.shape({
  topMakeShareStyle: PropTypes.shape({
    styleId: PropTypes.number,
    NEW: PropTypes.number,
    USED: PropTypes.number,
  }),
});

const PubStates = PropTypes.shape({
  NEW: PropTypes.bool,
  USED: PropTypes.bool,
  NEW_USED: PropTypes.bool,
  PRE_PROD: PropTypes.bool,
});

const Make = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  pubStates: PubStates,
  slug: PropTypes.string,
  adTargetId: PropTypes.string,
});

const Makes = PropTypes.arrayOf(Make);

const MakeModel = PropTypes.shape({
  name: PropTypes.string,
  pubStates: PubStates,
  make: Make,
  modelLinkCode: PropTypes.string,
  slug: PropTypes.string,
  adTargetId: PropTypes.string,
  prices: Prices,
});

const MakeModelSubmodel = PropTypes.shape({
  name: PropTypes.string,
  pubStates: PubStates,
  make: Make,
  model: MakeModel,
  slug: PropTypes.string,
  adTargetId: PropTypes.string,
});

const ModelYear = PropTypes.shape({
  makeName: PropTypes.string,
  modelName: PropTypes.string,
  year: PropTypes.number,
});

const ModelYears = PropTypes.arrayOf(ModelYear);

const MakeModelSubmodelYear = PropTypes.shape({
  id: PropTypes.number,
  pubStates: PubStates,
  make: Make,
  model: MakeModel,
  modelYearId: PropTypes.number,
  submodels: MakeModelSubmodel,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const MakeModelSubmodelsYear = PropTypes.shape({
  id: PropTypes.number,
  pubStates: PubStates,
  make: Make,
  model: MakeModel,
  modelYearId: PropTypes.number,
  submodels: PropTypes.arrayOf(MakeModelSubmodel),
  year: PropTypes.string,
});

const PopularStyles = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })
);

const StyleSpecifications = PropTypes.shape({
  epaCombinedMPG: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const StyleEditorialReviews = PropTypes.shape({
  ratings: PropTypes.shape({
    rating: PropTypes.number,
  }),
});

const StyleConsumerReviewsRatings = PropTypes.shape({
  averageRating: PropTypes.number,
  reviewsCount: PropTypes.number,
});

const StyleEstimatedPricing = PropTypes.shape({
  estimatedSavings: PropTypes.number,
});

const StyleTransactionCount = PropTypes.shape({
  level: PropTypes.string,
  group: PropTypes.string,
  transactionCount: PropTypes.shape({
    tier1: PropTypes.number,
    tier2: PropTypes.number,
    tier3: PropTypes.number,
    tier4: PropTypes.number,
  }),
  nationalTransactionCount: PropTypes.shape({
    tier1: PropTypes.number,
    tier2: PropTypes.number,
    tier3: PropTypes.number,
    tier4: PropTypes.number,
  }),
});

const StyleInsurancePrices = PropTypes.shape({
  monthlyValue: PropTypes.number,
});

const ConditionPricingValues = PropTypes.shape({
  current: PropTypes.shape({}),
  upper: PropTypes.shape({}),
  lower: PropTypes.shape({}),
});

const StyleTaxesFees = PropTypes.shape({
  total: PropTypes.number,
  taxes: PropTypes.shape({
    rate: PropTypes.shape({
      citySalesTax: PropTypes.number,
      countySalesTax: PropTypes.number,
      combinedSalesTax: PropTypes.number,
      districtSalesTax: PropTypes.number,
      stateSalesTax: PropTypes.number,
    }),
    amount: PropTypes.shape({
      citySalesTax: PropTypes.number,
      countySalesTax: PropTypes.number,
      combinedSalesTax: PropTypes.number,
      districtSalesTax: PropTypes.number,
      stateSalesTax: PropTypes.number,
    }),
  }),
  fees: PropTypes.shape({
    titleFee: PropTypes.number,
    registrationFee: PropTypes.number,
    dmvFee: PropTypes.number,
    combinedFees: PropTypes.number,
    dmvFeesItemized: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })
    ),
  }),
});

const NewTmv = PropTypes.shape({
  totalWithOptions: PropTypes.shape({
    baseMSRP: PropTypes.number,
    tmv: PropTypes.number,
  }),
  incentivesAndRebates: PropTypes.number,
});

const NewTmvAdjustment = PropTypes.shape({
  tmvAdjCorePercent: PropTypes.number,
  tmvAdjDealerCash: PropTypes.number,
});

const StylePricing = PropTypes.shape({
  estimatedPricing: StyleEstimatedPricing,
  newTmv: NewTmv,
  usedTmv: PropTypes.shape({
    baseMSRP: PropTypes.number,
    tmv: PropTypes.number,
    usedTmvRetail: PropTypes.number,
    usedPrivateParty: PropTypes.number,
    withoutOptions: PropTypes.shape({
      OUTSTANDING: ConditionPricingValues,
      CLEAN: ConditionPricingValues,
      AVERAGE: ConditionPricingValues,
      ROUGH: ConditionPricingValues,
    }),
  }),
});

const StyleProps = {
  id: PropTypes.number,
  makeName: PropTypes.string,
  makeSlug: PropTypes.string,
  modelName: PropTypes.string,
  modelSlug: PropTypes.string,
  trimName: PropTypes.string,
  trim: PropTypes.string,
  name: PropTypes.string,
  numberOfSeats: PropTypes.number,
  pricing: StylePricing,
  publicationState: PropTypes.string,
  editorialReviews: StyleEditorialReviews,
  year: PropTypes.number,
  specifications: StyleSpecifications,
  consumerReviewsRatings: StyleConsumerReviewsRatings,
};

const Style = PropTypes.shape({
  ...StyleProps,
  submodels: MakeModelSubmodel,
});

const StyleWithoutSubmodels = PropTypes.shape(StyleProps);

const Styles = PropTypes.arrayOf(Style);

const TrimStylePrice = PropTypes.shape({
  baseMSRP: PropTypes.number,
  usedTmvRetail: PropTypes.number,
  usedPrivateParty: PropTypes.number,
  usedTradeIn: PropTypes.number,
});

const TrimStyleFeatures = PropTypes.shape({
  transmissionType: PropTypes.string,
  bodyType: PropTypes.string,
  bedLengthInFeet: PropTypes.string,
  displacement: PropTypes.string,
  driveType: PropTypes.string,
  engineFuelType: PropTypes.string,
});

const TrimStyleEntity = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  make: Make,
  model: MakeModel,
  year: PropTypes.number,
  publicationState: PropTypes.string,
  submodels: PropTypes.arrayOf(MakeModelSubmodel),
  price: TrimStylePrice,
  trimFeatures: TrimStyleFeatures,
});

const TrimNationalPrices = PropTypes.objectOf(
  PropTypes.shape({
    baseMSRP: PropTypes.number,
    baseInvoice: PropTypes.number,
    deliveryCharges: PropTypes.number,
    tmv: PropTypes.number,
    estimateTmv: PropTypes.number,
    tmvRecommendedRating: PropTypes.number,
  })
);

const Vehicle = PropTypes.shape({
  make: PropTypes.shape({
    name: PropTypes.string,
    niceName: PropTypes.string,
  }),
  model: PropTypes.shape({
    name: PropTypes.string,
    niceName: PropTypes.string,
  }),
  modelYear: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  }),
  style: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  publicationState: PropTypes.string,
});

const TrimEntity = PropTypes.shape({
  trimName: PropTypes.string,
  trimSlug: PropTypes.string,
  styles: PropTypes.arrayOf(TrimStyleEntity),
  nationalPrices: TrimNationalPrices,
});

const Submodel = PropTypes.shape({
  pubStates: PubStates,
  make: Make,
  model: MakeModel,
  name: PropTypes.string,
  years: PropTypes.arrayOf(MakeModelSubmodelYear),
});

const ModelYearsByMakeId = PropTypes.arrayOf(
  PropTypes.shape({
    makeName: PropTypes.string,
    makeSlug: PropTypes.string,
    modelName: PropTypes.string,
    modelSlug: PropTypes.string,
    year: PropTypes.number,
    engineTypes: PropTypes.arrayOf(PropTypes.string),
  })
);

const GenericFeature = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  niceName: PropTypes.string,
  niceId: PropTypes.string,
  displayName: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

const GenericFeatures = PropTypes.objectOf(PropTypes.arrayOf(GenericFeature));

const VehicleYear = PropTypes.shape({
  id: PropTypes.number,
  year: PropTypes.string,
  pubStates: PubStates,
  make: Make,
  model: MakeModel,
  modelYearId: PropTypes.number,
  modelLinkCode: PropTypes.string,
  submodels: PropTypes.arrayOf(MakeModelSubmodel),
  newDefaultStyleId: PropTypes.number,
  usedDefaultStyleId: PropTypes.number,
  mostPopularStyleId: PropTypes.number,
  mostPopularSubmodel: PropTypes.number,
});

const LatestVehicleYears = PropTypes.arrayOf(VehicleYear);

const StyleEvTestedData = PropTypes.shape({
  range: PropTypes.number,
  rangeAverageByBodyType: PropTypes.number,
  consumptionKWHPer100Mi: PropTypes.number,
  consumptionAvgByBodyTypeKWHPer100Mi: PropTypes.number,
  testedStyleNameOverride: PropTypes.string,
  totalChargingTimeMMSS: PropTypes.string,
});

const FuelCostPart = {
  fuelGrade: PropTypes.string,
  pricePerGallon: PropTypes.number,
  pricePerKWh: PropTypes.number,
};

// Listed only fields in use. Add other fields if needed
const StyleFuelCostData = PropTypes.shape({
  vehicleData: PropTypes.shape({
    engineType: PropTypes.string,
    engineFuelType: PropTypes.string,
    primaryBodyType: PropTypes.string,
    vehicleSizeClass: PropTypes.string,
  }),
  fuelCosts: PropTypes.shape({
    ...FuelCostPart,
    primary: PropTypes.shape(FuelCostPart),
    secondary: PropTypes.shape(FuelCostPart),
    costPerYear: PropTypes.number,
    costPerMonth: PropTypes.number,
  }),
  statistics: PropTypes.shape({
    byBodyTypeAndSize: PropTypes.shape({
      combustionEngines: PropTypes.shape({
        averageCostPerMonth: PropTypes.number,
        averagePricePerGallon: PropTypes.number,
      }),
    }),
  }),
});

export const VehicleEntities = {
  Make,
  Makes,
  MakeModel,
  MakeModelSubmodel,
  MakeModelSubmodelYear,
  MakeModelSubmodelsYear,
  PubStates,
  Prices,
  TrimEntity,
  TrimStyleEntity,
  TrimStylePrice,
  TrimStyleFeatures,
  TrimNationalPrices,
  PopularStyles,
  Styles,
  Style,
  StylePricing,
  StyleConsumerReviewsRatings,
  StyleEditorialReviews,
  StyleEstimatedPricing,
  StyleSpecifications,
  StyleInsurancePrices,
  StyleWithoutSubmodels,
  StyleTaxesFees,
  StyleTransactionCount,
  StyleEvTestedData,
  StyleFuelCostData,
  NewTmv,
  NewTmvAdjustment,
  Vehicle,
  Submodel,
  ModelYears,
  ModelYearsByMakeId,
  GenericFeatures,
  GenericFeature,
  LatestVehicleYears,
};

const FEATURES_SUBSET = (() =>
  [
    'features.standard.Drive Train',
    'features.standard.Engine',
    'features.standard.Fuel',
    'features.standard.Measurements',
    'features.standard.Warranty',
    'price.baseMSRP',
    'styleAttributes',
    'totalSeating',
    'styleEndDate',
  ].join(','))();

export const GENERIC_FEATURES_PATH =
  '/vehicle/v3/consumergenericfeatures/?view=custom%2CfieldsInclude%3Aname%2CsimpleName%2Ctags%2CniceId';

export function buildMmysMsrpPricesPath({ make, model, year, submodel }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].msrpPriceRanges`;
}

export function buildMmyTrimsPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].year["${year}"].trims`
    : `makes["${make}"].models["${model}"].year["${year}"].trims`;
}

export function buildTrimsAndPricePath({ make, model, year, submodel }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].year["${year}"].trimsAndPrice`;
}

export function buildSubmodelsPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].submodelsByYear["${year}"]`;
}

export function buildSubmodelYearsPath({ make, model }) {
  return `makes["${make}"].models["${model}"].submodel.years`;
}

export function buildYearsByMMYSPath({ make, model, submodel }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years`;
}

export function buildActiveVehiclePath({ make, model }) {
  return `makes["${make}"].models["${model}"].activeVehicle`;
}

export function buildDefaultVehiclePath({ make, model }) {
  return `makes["${make}"].models["${model}"].activeVehicle.withReview`;
}

export function buildVehiclePath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]`
    : `makes["${make}"].models["${model}"].defaultSubmodel.years["${year}"]`;
}

export function buildNoSubmodelVehiclePath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"]`;
}

export function buildPopularStylesPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].styles`
    : `makes["${make}"].models["${model}"].defaultSubmodel.years["${year}"].styles`;
}

export function buildStylesPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].styles.years["${year}"]`
    : `makes["${make}"].models["${model}"].years["${year}"].styles`;
}

export function buildModelsPath({ make }) {
  return `makes["${make}"].models`;
}

export function buildModelYearsPath(make) {
  return `makes["${make}"].modelYears`;
}

export function buildVehicleStylePath({ styleId }) {
  return `styles.${styleId}`;
}

export function buildVehicleStyleInsurancePricesPath(styleId) {
  return `${buildVehicleStylePath({ styleId })}.insurancePrices`;
}

export function buildMakeModelPath({ make, model }) {
  return `makes["${make}"].models["${model}"]`;
}

export function buildMakeYearsPath(make) {
  return `makes["${make}"].years`;
}

export function buildYearMakesPath(year, pubStates = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED]) {
  return `year["${year}"].pubStates["${pubStates.sort().join()}"].makesList`;
}

export function buildMakeModelYearsPath({ make, model }) {
  return `makes["${make}"].models["${model}"].years`;
}

export function buildMakeModelDefaultYear({ make, model }) {
  return `makes["${make}"].models["${model}"].defaultYear`;
}

export function buildMakeModelIsMinimalViableDataPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].isMinimalViableData`;
}

export function buildLatestVehiclesPath({ make, model }) {
  return `makes["${make}"].models["${model}"].latestVehicles`;
}

export function buildMakeYearSubmodelsPath({ make, year }) {
  return `year["${year}"].makes["${make}"].submodels`;
}

export function buildMakeYearModelsPath(
  make,
  year,
  pubStates = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED]
) {
  return `year["${year}"].makes["${make}"].pubStates["${pubStates.sort().join()}"].models`;
}

export function buildMmyPubStatePath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].pubState`;
}

export function buildPubStateModelYearPath(pubStates = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED]) {
  return `modelYears.pubStates["${pubStates.sort().join()}"].years`;
}

export function buildMMYGenericFeaturesPath({ make, model, year, submodel = DEFAULT }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].genericFeatures`;
}

export function buildModelFamilyIdsModelYearsPath(modelFamilyIds) {
  return `modelFamilyIds["${modelFamilyIds.join(',')}"].modelYears`;
}

export function buildMmyModelFamilyIdsPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].modelFamilyIds`;
}

/**
 * Gets pricing path.
 * @param {number|string} styleId
 * @returns {string}
 */
export function buildUsedTmvPricingPath(styleId) {
  return `styles.${styleId}.pricing.usedTmv.withoutOptions`;
}

export function buildNewTmvPricingPath(styleId) {
  return `styles.${styleId}.pricing.newTmv.withoutOptions`;
}

export function buildNewTmvAdjustmentPath(styleId) {
  return `styles.${styleId}.pricing.newTmv.adjustment`;
}

export function buildTransactionCountPath(styleId) {
  return `styles.${styleId}.transactionCount`;
}

export function buildEvTestedDataPath(styleIds) {
  return styleIds.filter(value => value).length ? `styles.${styleIds.join()}.evTestedData` : null;
}

export function buildFuelCostsPath(
  styleId,
  { milesPerYear = DEFAULT, dailyCommute = DEFAULT, cityPercents = DEFAULT } = {}
) {
  return styleId
    ? `styles.${styleId}.fuelCosts.milesPerYear["${milesPerYear}"].cityPercents["${cityPercents}"].dailyCommute["${dailyCommute}"].data`
    : null;
}

export function buildTaxesFeesPath({ styleId, msrp, dealerPrice }) {
  if (!styleId || !msrp || !dealerPrice) {
    return null;
  }

  return `styles.${styleId}.taxesFees.msrp["${msrp}"].dealerPrice["${dealerPrice}"]`;
}

export function getBaseStylePath({ make, model, year } = {}) {
  if (!(make && model && year)) {
    return null;
  }

  return `makes["${make}"].models["${model}"].years["${year}"].baseStyle`;
}

export function getUsedBaseStylePath({ make, model, year } = {}) {
  if (!(make && model && year)) {
    return null;
  }

  return `makes["${make}"].models["${model}"].years["${year}"].usedBaseStyle`;
}

// Features specs paths below
function buildStyleWithFeaturesPath({ styleId }) {
  return `stylesWithFeatures["${styleId}"]`;
}

function buildStyleFeaturesPath(styleId) {
  return `features.styles["${styleId}"]`;
}

function buildStyleAttributesPath(styleId) {
  return `features.styleAttributes.styles["${styleId}"]`;
}

function buildPartialFeaturesPath({ make, model, submodel, year }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].stylesPartialFeaturesSpecs`;
}

function buildMmysStyleAttributesPath({ make, model, submodel, year }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].styleAttributes`;
}

export const FeatureSpecsPaths = {
  buildPartialFeaturesPath,
  buildMmysStyleAttributesPath,
  buildStyleWithFeaturesPath,
  buildStyleFeaturesPath,
  buildStyleAttributesPath,
};

export function convertV3toMakeModelSubmodelsYear(v3, makeSlug, modelSlug) {
  return {
    year: `${v3.year}`,
    id: v3.newDefaultSubmodel.id,
    pubStates: v3.publicationStates.reduce((accumulator, pubState) => {
      const accum = accumulator;
      accum[pubState] = true;
      return accum;
    }, {}),
    make: {
      $ref: `#/makes/${makeSlug}`,
    },
    model: {
      $ref: `#/makes/${makeSlug}/models/${modelSlug}`,
    },
    modelYearId: v3.id,
    modelLinkCode: v3.modelLinkCode,
    submodels: v3.submodels.map(submodel => ({
      $ref: `#/makes/${makeSlug}/models/${modelSlug}/submodels/${submodel.niceId}`,
    })),
    newDefaultStyleId: get(v3, 'newDefaultStyle.id'),
    usedDefaultStyleId: get(v3, 'usedDefaultStyle.id'),
    mostPopularStyleId: get(v3, 'mostPopularStyle.id'),
    mostPopularSubmodel: get(v3, 'mostPopularStyle.subModels[0]'),
    isMinimalViableData: get(v3, 'attributeGroups.MAIN.properties.MINIMUM_VIABLE_DATA') === 'Y',
  };
}

const EMPTY_CONSUMERS_RATING = {
  ratings: {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  },
  averageRating: null,
  total: null,
};
const EMPTY_FIVE_YEARS_TCO = {
  total: null,
  years: {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  },
};

// TODO: looks like we missed slugs in the v4 api spec
function generateSlugs(items, slugName = 'slug') {
  if (items && typeof items === 'object') {
    return reduce(
      items,
      (result, value, key) => {
        if (typeof value === 'string') {
          return {
            ...result,
            [key]: value,
          };
        }
        const withSlugs = {
          ...result,
          [key]: {
            ...value,
            [slugName]: key,
          },
        };

        if (value.submodels) {
          withSlugs[key].submodels = generateSlugs(value.submodels);
        }

        return withSlugs;
      },
      {}
    );
  }

  return items;
}

function getAverageRating(items, total) {
  const average = reduce(items, (result, curValue, index) => result + curValue * index, 0) / total;
  return parseFloat(average.toFixed(1));
}

function parseConsumerRatings({ results }) {
  const { total } = results;
  const ratings = pick(results, ['1', '2', '3', '4', '5']);
  const averageRating = total ? getAverageRating(ratings, total) : 0;

  return {
    ratings,
    averageRating,
    total,
  };
}

const { placeholder } = partial;
/**
 * True if style is new
 * @param {{publicationState: string}} style
 * @return {bool}
 */
const isNewStylePredicate = ({ publicationState }) => isNewState(publicationState);
/**
 * Filters styles collection leaving only new styles
 * @return {{}[]} - collection of new styles
 */
const filterOnlyNewStyles = partial(filter, placeholder, isNewStylePredicate);
/**
 * Gets only new styles ids from collection of styles
 * @return {number[]}
 */
const getNewStylesIds = flow(
  filterOnlyNewStyles,
  partial(map, placeholder, 'id')
);

function hasAllParameters(...parameters) {
  return every(parameters, parameter => parameter && parameter !== 'undefined');
}

// TODO: workaround for misbehaving style apis, remove when apis are cleaned up
export function processVehicleStyle(style) {
  const { $identity, submodels, trim, trimName, ...processedStyle } = style;

  return {
    ...processedStyle,
    submodels: isArray(submodels) ? submodels[0] : submodels,
    trimName: trimName || trim,
  };
}

/**
 * Returns: {latest NEW, non-Preprod} -> {Preprod if latest non-Preprod is USED} -> {latest USED, non-Preprod}
 */
function filterLatestNonPreprod(vehicles) {
  if (!vehicles || !vehicles.length) {
    return null;
  }
  const preprod = vehicles.findIndex(vehicle => vehicle.pubStates.PRE_PROD);
  const nonPreprod = vehicles.findIndex(vehicle => !vehicle.pubStates.PRE_PROD);

  return vehicles[nonPreprod] && !vehicles[nonPreprod].pubStates.USED
    ? vehicles[nonPreprod] || vehicles[preprod]
    : vehicles[preprod] || vehicles[nonPreprod];
}

/**
 * Builds refs in model submodels
 * @param {object} models
 * @param {string} makeSlug
 * @returns {object}
 */
function buildSubmodelsRefs(models, makeSlug) {
  return reduce(
    models,
    (submodelMap, model) => {
      const { submodels } = model;
      const submodelRefs = reduce(
        submodels,
        (refs, submodel) => ({
          ...refs,
          [`${model.slug}_${submodel.slug}`]: {
            $ref: `#/makes/${makeSlug}/models/${model.slug}/submodels/${submodel.slug}`,
          },
        }),
        {}
      );

      return {
        ...submodelMap,
        ...submodelRefs,
      };
    },
    {}
  );
}

/**
 * Filters available submodels in models.
 * @param {object} models
 * @param {object} availableSubmodels
 * @returns {object}
 */
export function filterAvailableSubmodels(models, availableSubmodels) {
  return reduce(
    models,
    (acc, val, key) => {
      // get available submodel by model key
      const submodel = availableSubmodels[key];
      if (submodel) {
        // if submodel is presented then keep only available submodels in model
        acc[key] = { ...val, submodels: pick(val.submodels, Object.entries(submodel).map(([_key]) => _key)) };
      }
      return acc;
    },
    {}
  );
}

export function buildAllTmvBandsUrl({ styleId, zipCode, mileage, colorId, optionIds }) {
  if (!zipCode || !styleId) {
    return null;
  }

  return formatAllTmvBandsUrl({ styleId, zipCode, mileage, colorId, optionIds });
}

export function buildNewTmvUrl({ styleId, zipCode }) {
  return `/newtmv/v3/calculate?styleid=${styleId}&zip=${zipCode}&typical=false`;
}

/**
 * NOTE: VIN-specific API resolvers can be found in the￼vehicle-vin.js data model.
 * Platform team requested the separation to reduce bloat in vehicle.js.
 */
export const VehicleModel = createModelSegment('vehicle', [
  {
    path: 'makes',
    resolve(match, context) {
      const url = '/vehicle/v4/makes/';
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url, { timeout: 1000, cache: 'force-cache' }) // increased timeout due to large payload triggering response timeout in node-fetch
        .then(({ results }) => generateSlugs(results));
    },
  },
  {
    path: 'makesList',
    resolve(match, context) {
      return context.resolveValue('makes').then(makesHash => {
        if (makesHash) {
          return toArray(makesHash)
            .sort(sortByName)
            .map(val => ({ $ref: `#/makes/${val.slug}` }));
        }
        return [];
      });
    },
  },
  /**
   * @see buildModelsPath
   */
  {
    path: 'makes["{slug}"].models',
    resolve({ slug }, context) {
      return context.resolveValue(`makes["${slug}"]`).then(make => {
        if (make) {
          const url = `/vehicle/v4/makes/${slug}/submodels/`;
          return withMetrics(EdmundsAPI, context)
            .fetch(url, { timeout: 700 }) // increased timeout due to large payload triggering response timeout in node-fetch
            .then(response => response.json().then(({ results }) => generateSlugs(results)))
            .catch(() => ({}));
        }

        return {};
      });
    },
  },
  /**
   * http://www.edmunds.com/api/vehicle/v4/makes/honda/models/
   *
   * @return VehicleEntities.MakeModel - with prices
   */
  {
    path: 'makes["{make}"].models["{model}"].prices',
    resolve({ make, model }, context) {
      const url = `/vehicle/v4/makes/${make}/models/`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => {
          if (results && results[model]) {
            return results[model].prices;
          }
          return {};
        });
    },
  },
  /**
   * http://qa-21-www.edmunds.com/api/vehicle/v3/styles?makeNiceId=ford&modelNiceId=escape&fields=trim
   */
  {
    path: 'makes["{make}"].models["{model}"].trims',
    resolve({ make, model }, context) {
      const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&fields=trim&pagesize=all&pagenum=1`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .catch(() => ({}));
    },
  },
  /**
   * TODO: we will create v4 version of trims API via TRAF-2463
   * http://qa-21-www.edmunds.com/api/vehicle/v3/styles?makeNiceId=ford&modelNiceId=escap&year=2017e&fields=trim
   */
  {
    path: 'makes["{make}"].models["{model}"].year["{year}"].trims',
    resolve({ make, model, year }, context) {
      const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&fields=trim&pagesize=all&pagenum=1`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results.map(item => item.trim))
        .catch(() => ({}));
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].trims',
    resolve({ make, model, submodel }, context) {
      const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&subModels.niceId=${submodel}&fields=trim&pagesize=all&pagenum=1`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .catch(() => ({}));
    },
  },
  /**
   * https://qa-21-www.edmunds.com/gateway/api/vehicle/v3/styles?makeNiceId=honda&modelNiceId=accord&year=2017&subModels.niceId=coupe&fields=trim,price&pagesize=all&pagenum=1
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].year["{year}"].trimsAndPrice',
    resolve({ make, model, year, submodel }, context) {
      const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&subModels.niceId=${submodel}&fields=trim,price&pagesize=all&pagenum=1`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results)
        .catch(() => ({}));
    },
  },
  /**
   * TODO: we will create v4 version of trims API via TRAF-2463
   * https://qa-21-www.edmunds.com/gateway/api/vehicle/v3/styles?makeNiceId=honda&modelNiceId=accord&year=2017&subModels.niceId=coupe&fields=trim&pagesize=all&pagenum=1
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].year["{year}"].trims',
    resolve({ make, model, year, submodel }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].submodels["${submodel}"].year["${year}"].trimsAndPrice`)
        .then(results => results.map(item => item.trim))
        .catch(() => ({}));
    },
  },
  /**
   * http://www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=honda&pagesize=4&modelNiceId=accord&sortby=year:DESC&pagenum=1&fields=publicationStates,subModels.identifier,subModels.name,subModels.id,subModels.niceId,makeName,modelName,id,year,newDefaultStyle.id,usedDefaultStyle.id,newDefaultSubmodel.id,makeId,modelLinkCode,attributeGroups.MAIN.properties.MINIMUM_VIABLE_DATA
   * @returns {Array.<VehicleEntities.MakeModelSubmodelsYear>}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].latestVehicles',
    resolve({ makeSlug, modelSlug }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&pagesize=11&modelNiceId=${modelSlug}&sortby=year%3ADESC&pagenum=1&fields=publicationStates%2CsubModels.identifier%2CsubModels.name%2CsubModels.id%2CsubModels.niceId%2CmakeName%2CmodelName%2Cid%2Cyear%2CnewDefaultStyle.id%2CusedDefaultStyle.id%2CnewDefaultSubmodel.id%2CmakeId%2CmodelLinkCode,attributeGroups.MAIN.properties.MINIMUM_VIABLE_DATA`;
      return withMetrics(EdmundsAPI, context)
        .fetch(url)
        .then(response => response.json())
        .then(responseObj =>
          get(responseObj, 'results', []).map(v3 => convertV3toMakeModelSubmodelsYear(v3, makeSlug, modelSlug))
        );
    },
  },
  /**
   * Returns first matched vehicle based on make, model and year params.
   * http://www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=honda&pagesize=1&modelNiceId=accord&year=2020&pagenum=1&fields=publicationStates,subModels.identifier,subModels.name,subModels.id,subModels.niceId,makeName,modelName,id,year,newDefaultStyle.id,usedDefaultStyle.id,mostPopularStyle.id,mostPopularStyle.subModels,newDefaultSubmodel.id,makeId,modelLinkCode
   * @returns {Object.<VehicleEntities.MakeModelSubmodelsYear>|{}}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"]',
    async resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&pagesize=1&modelNiceId=${modelSlug}&year=${year}&pagenum=1&fields=publicationStates%2CsubModels.identifier%2CsubModels.name%2CsubModels.id%2CsubModels.niceId%2CmakeName%2CmodelName%2Cid%2Cyear%2CnewDefaultStyle.id%2CusedDefaultStyle.id%2CmostPopularStyle.id%2CmostPopularStyle.subModels%2CnewDefaultSubmodel.id%2CmakeId%2CmodelLinkCode`;

      let response;
      try {
        response = await withMetrics(EdmundsAPI, context).fetchJson(url);
        const firstResult = get(response, 'results.0', {});
        response = convertV3toMakeModelSubmodelsYear(firstResult, makeSlug, modelSlug);
      } catch (ex) {
        response = {};
      }

      return response;
    },
  },
  /**
   * @see buildMmyPubStatePath
   * Returns MMY publication state
   * https://www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=honda&modelNiceId=accord&year=2021&fields=publicationStates
   * @returns {String}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].pubState',
    resolve({ makeSlug, modelSlug, year }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&modelNiceId=${modelSlug}&year=${year}&fields=publicationStates`
        )
        .then(response => get(response, 'results[0].publicationStates[0]'))
        .catch(() => null);
    },
  },
  /**
   * @see buildPubStateModelYearPath
   */
  {
    path: 'modelYears.pubStates["{pubStates}"].years',
    async resolve({ pubStates }, context) {
      const url = `/vehicle/v3/modelYears?publicationStates=${pubStates}&distinct=year:DESC`;
      const years = await withMetrics(EdmundsAPI, context).fetchJson(url);
      return years.map(year => ({ year }));
    },
  },
  {
    path: 'modelYears.type["{typeSlug}"]',
    async resolve({ typeSlug }, context) {
      const url = `/vehicle/v3/modelYears?publicationStates=NEW,NEW_USED&${getQueryTypeStringModelYears(
        typeSlug
      )}&fields=makeName,modelName,year,subModels&pagenum=1&pagesize=all`;
      const { results } = await withMetrics(EdmundsAPI, context).fetchJson(url);
      return results;
    },
  },
  /**
   * Notice that this path resolves to `null` for cases where there are only preprod vehicles.
   * @returns {{ ref: VehicleEntities.MakeModelSubmodelsYear }|null}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].latestNonPreprodVehicle',
    resolve({ makeSlug, modelSlug }, context) {
      return context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"].latestVehicles`).then(latestVehicles => {
        let refIndex = -1;

        if (latestVehicles && latestVehicles.length) {
          refIndex = latestVehicles.findIndex(vehicle => !vehicle.pubStates.PRE_PROD);
        }

        // return null when there are only preprod vehicles
        return refIndex > -1
          ? { ref: { $ref: `#/makes/${makeSlug}/models/${modelSlug}/latestVehicles/${refIndex}` } }
          : null;
      });
    },
  },
  /**
   * This path returns the latest MakeModelSubmodelYear vehicle with only {make} and {model} params.
   * The vehicle returned is determinted by the following order of preference, from high to low:
   *
   * Returns: {latest NEW, non-Preprod} -> {Preprod if latest non-Preprod is USED} -> {latest USED, non-Preprod}
   *
   * It is currently being used to traffic '/make/model' to the core page.
   * @returns {VehicleEntities.MakeModelSubmodelYear|null}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].activeVehicle',
    resolve({ makeSlug: make, modelSlug: model }, context) {
      return context.resolveValue(`makes["${make}"].models["${model}"].latestVehicles`).then(vehicles => {
        const vehicle = filterLatestNonPreprod(vehicles);

        return vehicle
          ? context
              .resolveValue(`makes["${make}"].models["${model}"].defaultSubmodel.years["${vehicle.year}"]`)
              .then(mmsy => ({
                $ref: `#/makes/${make}/models/${model}/submodels/${mmsy.submodels.slug}/years/${mmsy.year}`,
              }))
          : null;
      });
    },
  },
  /**
   * @see buildDefaultVehiclePath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].activeVehicle.withReview',
    resolve({ makeSlug: make, modelSlug: model }, context) {
      return context
        .resolveValue(buildMakeModelDefaultYear({ make, model }))
        .then(year => {
          if (!year) {
            return null;
          }

          return context
            .resolveValue(`makes["${make}"].models["${model}"].defaultSubmodel.years["${year}"]`)
            .then(mmsy => ({
              $ref: `#/makes/${make}/models/${model}/submodels/${mmsy.submodels.slug}/years/${mmsy.year}`,
            }));
        })
        .catch(() => null);
    },
  },
  /**
   * @see buildStylesPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodels["{submodelSlug}"].styles.years["{year}"]',
    resolve({ makeSlug, modelSlug, submodelSlug, year }, context) {
      const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/submodels/${submodelSlug}/years/${year}/styles/?fields=id,name,niceName,niceId,trim(name),price.baseMSRP`;

      return (
        withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(({ results }) => filterFleetTrims(sortBy(results, 'price.baseMSRP'), context))
          // TODO: workaround for missing/incorrect fields in mmy styles api, remove after api is cleaned up
          .then(
            styles =>
              styles &&
              styles.filter(({ publicationState }) => !isDisabledState(publicationState)).map(processVehicleStyle)
          )
      );
    },
  },
  /**
   * @see buildStylesPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].styles',
    resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/years/${year}/styles/`;

      return (
        withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(({ results }) => filterFleetTrims(results, context))
          // TODO: workaround for missing/incorrect fields in mmy styles api, remove after api is cleaned up
          .then(
            styles =>
              styles &&
              styles.filter(({ publicationState }) => !isDisabledState(publicationState)).map(processVehicleStyle)
          )
      );
    },
  },
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodels["{submodelSlug}"].years',
    resolve({ makeSlug, modelSlug, submodelSlug }, context) {
      const submodelPath = `makes["${makeSlug}"].models["${modelSlug}"].submodels["${submodelSlug}"]`;
      return context.resolveValue(submodelPath).then(submodel => {
        if (submodel) {
          const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/submodels/${submodelSlug}/years/`;
          return withMetrics(EdmundsAPI, context)
            .fetch(url)
            .then(response => response.json().then(({ results }) => generateSlugs(results, 'year')));
        }

        return {};
      });
    },
  },
  {
    path: 'makes["{slug}"].submodels',
    resolve({ slug }, context) {
      return context.resolveValue(`makes["${slug}"].models`).then(models => buildSubmodelsRefs(models, slug));
    },
  },
  {
    path: 'makes["{makeSlug}"].latestSubmodels',
    async resolve({ makeSlug }, context) {
      const url = `/vehicle/v3/submodels?makeNiceId=${makeSlug}&year=${getLatestYears()}&pageSize=550&pageNum=1`;

      const [{ results }, models] = await Promise.all([
        withMetrics(EdmundsAPI, context).fetchJson(url),
        context.resolveValue(`makes["${makeSlug}"].models`),
      ]);

      const availableSubmodels = results.reduce((acc, { modelNiceId, niceId }) => {
        set(acc, `${modelNiceId}.${niceId}`, true);
        return acc;
      }, {});
      const filteredModelsWithSubmodels = filterAvailableSubmodels(models, availableSubmodels);

      return buildSubmodelsRefs(filteredModelsWithSubmodels, makeSlug);
    },
  },
  /**
   * @see buildMakeYearSubmodelsPath
   */
  {
    path: 'year["{year}"].makes["{slug}"].submodels',
    resolve({ slug, year }, context) {
      const url = `/vehicle/v3/submodels?makeNiceId=${slug}&year=${year}&fields=name,niceId,modelNiceId,publicationStates&sortby=name&pagesize=1000&pagenum=1`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results)
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            // this is expected for invalid vehicles
            return null;
          }
          throw ex;
        });
    },
  },
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodelsByName["{submodelName}"].years["{year}"]',
    resolve({ makeSlug, modelSlug, submodelName, year }, context) {
      return context
        .resolveValue(`makes["${makeSlug}"].models`)
        .then(models => {
          const submodelSlug = findKey(models[modelSlug].submodels, { name: submodelName });
          if (submodelSlug) {
            const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/submodels/${submodelSlug}/years/`;
            return withMetrics(EdmundsAPI, context)
              .fetch(url)
              .then(response => response.json().then(({ results }) => generateSlugs(results, 'year')))
              .then(submodelYears => submodelYears[year]);
          }

          throw new Error('Wrong submodel passed. Please pass full submodel name in url, ex. Accord Sedan');
        })
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            return null; // this is expected for invalid vehicles
          }
          throw ex;
        });
    },
  },
  /**
   * The generic features is a list of possible features a car can have such as 'Heated Seats'. This is nice because
   * there is variance between manufacturers on what a feature is called.
   */
  {
    path: 'genericFeatures',
    resolve(match, context) {
      return withMetrics(EdmundsAPI, context).fetchJson(GENERIC_FEATURES_PATH);
    },
  },
  {
    /**
     * TODO Migrate to v4 vehicle api
     * TODO Return a $ref to the submodel once the bug is fixed
     * Most popular submodel - Also default submodel
     * https://www.edmunds.com/gateway/api/vehicle/v3/submodels/?pagesize=1&sortby=makeShare%3ADESC&pagenum=1&makeNiceId=ford&modelNiceId=f150&year=2017&fields=id%2Cname%2Ctrim.name%2CsubModels.name
     * makes["honda"].models["accord"].defaultSubmodel.years["2017"]
     */
    path: 'makes["{make}"].models["{model}"].defaultSubmodel.years["{year}"]',
    async resolve({ make, model, year }, context) {
      return context
        .resolveValue(
          `makes["${make}"].models["${model}"].defaultSubmodelSlugAndPubState.years["${year}"]`,
          VehicleDefaultModel
        )
        .then(defaultSubmodel => {
          if (!defaultSubmodel) {
            return null;
          }

          return context
            .resolveValue(`makes["${make}"].models["${model}"].submodels["${defaultSubmodel.slug}"].years["${year}"]`)
            .then(() => ({
              $ref: `#/makes/${make}/models/${model}/submodels/${defaultSubmodel.slug}/years/${year}`,
            }));
        });
    },
  },
  /**
   * @returns VehicleEntities.PopularStyles
   *
   * path: buildPopularStylesPath
   * api: https://qa-21-www.edmunds.com/api/vehicle/v4/makes/mercedes-benz/models/s-class/years/2017/styles
   */
  {
    path: 'makes["{make}"].models["{model}"].defaultSubmodel.years["{year}"].styles',
    resolve({ make, model, year }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/vehicle/v4/makes/${make}/models/${model}/years/${year}/styles`)
        .then(({ results }) => filterFleetTrims(results || null, context));
    },
  },
  /**
   * @returns VehicleEntities.PopularStyles
   *
   * path: buildPopularStylesPath
   * api: https://qa-21-www.edmunds.com/api/vehicle/v4/makes/mercedes-benz/models/s-class/submodels/amg-s-65/years/2017/styles
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].styles',
    resolve({ make, model, year, submodel }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/vehicle/v4/makes/${make}/models/${model}/submodels/${submodel}/years/${year}/styles`)
        .then(({ results }) => filterFleetTrims(results || null, context));
    },
  },
  /**
   * Notice this returns an object of submodels with years inside the submodel object.
   * If you need a specific year, you should be using the
   * makes["{make}"].models["{model}"].defaultSubmodel.years["{year}"] path.
   *
   * example api: http://www.edmunds.com/api/vehicle/v4/makes/honda/models/accord/years
   *
   * @return PropTypes.shape({
   *   pubStates: PubStates,
   *   make: Make,
   *   model: MakeModel,
   *   name: PropTypes.string,
   *   years: PropTypes.objectOf(MakeModelSubmodelYear),
   * });
   * @see buildSubmodelYearsPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodel.years',
    async resolve({ makeSlug, modelSlug }, context) {
      // resolve make/model because API results contain $ref to this data
      return Promise.all([
        context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"]`),
        withMetrics(EdmundsAPI, context).fetchJson(`/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/years`),
      ]).then(response => get(response[1], 'results', {}));
    },
  },
  /**
   * Returns years object for selected make and model.
   * example api: http://www.edmunds.com/api/vehicle/v4/makes/honda/models/accord/years
   *
   * @return PropTypes.objectOf(MakeModelYears)
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years',
    resolve({ makeSlug, modelSlug }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/years`)
        .then(({ results: submodels }) => {
          const years = {};
          forEach(submodels, submodel => {
            forEach(submodel.years, (yearData, year) => {
              years[year] = {
                ...yearData,
                year,
              };
            });
          });
          return years;
        });
    },
  },
  /**
   * @see buildMakeYearsPath
   * Example: https://qa-21-www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=honda&publicationStates=USED,NEW_USED,NEW&distinct=year
   */
  {
    path: 'makes["{makeSlug}"].years',
    resolve({ makeSlug }, context) {
      return withMetrics(EdmundsAPI, context).fetchJson(
        `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&publicationStates=USED,NEW_USED,NEW&distinct=year`
      );
    },
  },
  /**
   * Notice this returns an array of submodels with for current make model year parameters.
   * example api: http://www.edmunds.com/api/vehicle/v4/makes/honda/models/accord/years/
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodelsByYear["{year}"]',
    resolve({ makeSlug, modelSlug, year }, context) {
      return context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"].submodel.years`).then(results =>
        reduce(
          results,
          (submodelList, submodelYears) => {
            const submodel = submodelYears.years[year] && submodelYears.years[year].submodels;
            return submodel
              ? [
                  {
                    $ref: `#/makes/${submodel.make.slug}/models/${submodel.model.slug}/submodels/${submodel.slug}`,
                  },
                  ...submodelList,
                ]
              : submodelList;
          },
          []
        )
      );
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].trims["{trim}"]',
    resolve({ make, model, year, trim }, context) {
      const url = `/vehicle/v4/makes/${make}/models/${model}/years/${year}/trims/${trim}/styles/`;
      return (
        withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(({ results }) => results)
          .then(({ styles, trimSlug, trimName }) => ({
            styles: sortBy(styles, 'price.baseMSRP'),
            trimName,
            trimSlug,
          }))
          // We need to catch this API cause it throws 404 Not Found if trim data for current make model year trim was
          // not found. Later we need to handle 'no styles for trim' case.
          .catch(ex => {
            if (ex.status === HTTP_NOT_FOUND) {
              return {};
            }
            throw ex; // This is most likely a timeout or broken data - throw up
          })
      );
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].trims["{trim}"].nationalPrices',
    resolve({ make, model, year, trim }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].trims["${trim}"]`)
        .then(({ styles }) => {
          const stylesIds = getNewStylesIds(styles);
          if (stylesIds.length) {
            return context.resolveValue('location', VisitorModel).then(({ stateCode }) => {
              const url = `/newtmv/v3/nationalprices?styleid=${stylesIds}&statecode=${stateCode}`;
              return withMetrics(EdmundsAPI, context)
                .fetchJson(url)
                .then(({ results }) => results);
            });
          }
          return {};
        });
    },
  },
  {
    path: 'year["{year}"].pubStates["{pubStates}"].makesList',
    resolve({ year, pubStates }, context) {
      const url = `/vehicle/v3/modelYears?year=${year}&publicationStates=${pubStates}&pagesize=all&pagenum=1&distinct=makeName`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(
          response =>
            response &&
            response
              .map(make => ({
                name: make,
                niceName: makeNiceName(make),
              }))
              .sort(sortByName)
        )
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            return {};
          }
          throw ex; // This is most likely a timeout or broken data - throw up
        });
    },
  },
  {
    path: 'year["{year}"].makes["{makeSlug}"].pubStates["{pubStates}"].models',
    resolve({ year, makeSlug, pubStates }, context) {
      const url = `/vehicle/v3/modelYears?year=${year}&makeNiceId=${makeSlug}&publicationStates=${pubStates}&pagesize=all&pagenum=1&fields=modelName,modelNiceId&sortby=modelName`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(
          response =>
            response &&
            response.results &&
            response.results.map(model => ({
              name: model.modelName,
              niceId: model.modelNiceId,
            }))
        )
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            return {};
          }
          throw ex; // This is most likely a timeout or broken data - throw up
        });
    },
  },
  {
    path: 'features.{style}',
    resolve({ style }, context) {
      const url = `/vehiclefeatures/v3/comparable-features/?styleid=${style}`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => get(response, ['results', style]));
    },
  },
  {
    path: 'paramFeatures.{style}',
    resolve({ style }, context) {
      return context.resolveValue(`features.${style}`).then(fValues => {
        const EMPTY_FEATURE_VALUE = 'N/A';

        return featuresMap.reduce(
          (result, { camelName, name, values }) =>
            Object.assign(result, {
              [name]: values.reduce((feature, value) => {
                const isValueObject = typeof value === 'object';
                const valuePath = value.path || `${camelName}.${value.camelName}`;
                let featureValue = get(fValues, valuePath, EMPTY_FEATURE_VALUE);
                if (typeof featureValue === 'string') {
                  featureValue = featureValue.replace(/\bn\/a\b/g, EMPTY_FEATURE_VALUE);
                }

                if (isValueObject && value.formatter) {
                  featureValue = value.formatter(featureValue);
                }
                const featureKey = isValueObject ? value.name : value;
                return Object.assign(feature, {
                  [featureKey]: featureValue || EMPTY_FEATURE_VALUE,
                });
              }, {}),
            }),
          {}
        );
      });
    },
  },
  {
    path: 'colorFeatures.{style}',
    resolve({ style }, context) {
      return context.resolveValue(`features.${style}`).then(({ colorFeatures = [] } = {}) =>
        colorFeatures.reduce((result, { title, type, primaryRgbValue }) => {
          const colors = primaryRgbValue.split(',');

          result[type] = result[type] || []; // eslint-disable-line no-param-reassign

          result[type].push({
            type,
            title,
            color: {
              r: Number(colors[0]),
              g: Number(colors[1]),
              b: Number(colors[2]),
            },
          });

          return result;
        }, {})
      );
    },
  },
  /**
   * This filter is used to collect last vehicle appraised style that used to get estimated appraisal values
   */
  {
    path: 'estimatedAppraisalStyle',
  },
  {
    path: 'estimatedAppraisalMileage',
  },
  /**
   * Vehicle style information including submodels information connected via refs
   * @see buildVehicleStylePath
   * @see VehicleEntities.Style
   */
  {
    path: 'styles.{style}',
    resolve({ style }, context) {
      const url = `/vehicle/v4/styles/?id=${style}`;
      return (
        withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(response => get(response, 'results[0]', {}))
          .then(styleData =>
            context
              .resolveValue(`makes["${styleData.makeSlug}"].models["${styleData.modelSlug}"].submodels`)
              .then(() => styleData)
          )
          // TODO: workaround for self-ref in api. Remove this after api is cleaned up.
          .then(processVehicleStyle)
          // We need to catch this API cause it throws 404 Not Found if style data for current style id was
          // not found. Later we need to handle 'no style for page' case on page level if we need.
          .catch(ex => {
            if (ex.status === HTTP_NOT_FOUND) {
              return {};
            }
            throw ex; // This is most likely a timeout or broken data - throw up
          })
      );
    },
  },
  {
    path: 'styles.{styleId}.pricing',
    resolve({ styleId }, context) {
      return context.resolveValue(`styles.${styleId}`).then(style => {
        if (style) {
          const url = `/vehicle/v3/styles?id=${style.id}&fields=price`;
          return withMetrics(EdmundsAPI, context)
            .fetch(url)
            .then(response => response.json())
            .then(response => response.results[0].price);
        }
        return {};
      });
    },
  },
  /**
   * Returns taxes and fees for given style id by msrp and dealer price
   * E.g. https://qa-21-www.edmunds.com/api/taxesfees/v1/?zipcode=90401&styleid=401661585&purchaseprice=17491&tradeinvalue=0&primarycustomercash=0&marketvalue=19489
   * @see buildTaxesFeesPath
   * @returns {StyleTaxesFees}
   */
  {
    path: 'styles.{styleId}.taxesFees.msrp["{msrp}"].dealerPrice["{dealerPrice}"]',
    async resolve({ styleId, msrp, dealerPrice }, context) {
      const { zipCode } = await context.resolveValue('location', VisitorModel);

      const url = `/taxesfees/v1/?zipcode=${zipCode}&styleid=${styleId}&purchaseprice=${dealerPrice}&marketvalue=${msrp}&tradeinvalue=0&primarycustomercash=0`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .catch(() => ({}));
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=chevrolet&modelNiceId=corvette-stingray&year=2014&fields=modelFamilyIds
   * @see buildMmyModelFamilyIdsPath
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].modelFamilyIds',
    resolve({ make, model, year }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${make}&modelNiceId=${model}&year=${year}&fields=modelFamilyIds`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => get(response, 'results[0].modelFamilyIds', []))
        .catch(() => []);
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/api/vehicle/v3/modelYears?modelFamilyIds=3279MF,676MF&sortby=minBaseMSRP:ASC&fields=makeName,modelName,year,publicationStates,makeNiceId,modelNiceId,types.engineType&pageSize=100&pageNum=1
   * @see buildModelFamilyIdsModelYearsPath
   */
  {
    path: 'modelFamilyIds["{modelFamilyIds}"].modelYears',
    resolve({ modelFamilyIds }, context) {
      const url = `/vehicle/v3/modelYears?modelFamilyIds=${modelFamilyIds}&sortby=minBaseMSRP:ASC&fields=makeName,modelName,year,publicationStates,makeNiceId,modelNiceId,types.engineType&pageSize=100&pageNum=1`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) =>
          results.map(({ publicationStates, makeName, modelName, makeNiceId, modelNiceId, year, types }) => ({
            publicationStates,
            makeName,
            modelName,
            makeSlug: makeNiceId,
            modelSlug: modelNiceId,
            year: year && year.toString(),
            engineType: types?.engineType?.[0],
          }))
        )
        .catch(() => []);
    },
  },
  /**
   * @see buildMmysMsrpPricesPath
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].msrpPriceRanges',
    resolve({ make, model, year, submodel }, context) {
      const pageSize = 20;
      const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&subModels.niceId=${submodel}&sortby=price.baseMSRP:DESC&fields=price.baseMSRP,attributeGroups.STYLE_INFO.attributes.STYLE_END_DATE&pagesize=${pageSize}&pagenum=1`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results, totalPages }) => ({ results: filterDiscontinuedStyles(results), totalPages }))
        .then(async ({ results, totalPages }) => {
          const msrpRange = []; // return empty array if no base prices exist (like for preprod vehicles)
          if (get(results, 'length')) {
            const maxPrice = get(results.find(style => !!get(style, 'price.baseMSRP')), 'price.baseMSRP');
            if (maxPrice) {
              msrpRange.push(maxPrice);
            }

            let lastPageMinPrice;
            let pageNum = totalPages;

            // Find first not discontinued style from the end and take its price
            while (!lastPageMinPrice && pageNum > 1) {
              const lastPageUrl = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&subModels.niceId=${submodel}&sortby=price.baseMSRP:DESC&fields=price.baseMSRP,attributeGroups.STYLE_INFO.attributes.STYLE_END_DATE&pagesize=${pageSize}&pagenum=${pageNum}`;
              // eslint-disable-next-line no-await-in-loop
              const lastPageResults = await withMetrics(EdmundsAPI, context)
                .fetchJson(lastPageUrl)
                .then(({ results: lastResults }) => filterDiscontinuedStyles(lastResults));

              lastPageMinPrice = get(
                findLast(lastPageResults, style => !!get(style, 'price.baseMSRP')),
                'price.baseMSRP'
              );

              pageNum -= 1;
            }

            if (!lastPageMinPrice && pageNum === 1) {
              lastPageMinPrice = get(results, `[${results.length - 1}].price.baseMSRP`);
            }

            if (lastPageMinPrice) {
              msrpRange.push(lastPageMinPrice);
            }
          }
          return msrpRange;
        });
    },
  },
  {
    path: 'styles.{styleId}.photo',
    resolve({ styleId }, context) {
      const url = `/media/v2/styles/${styleId}/photos/?shottype=FQ&category=exterior&provider=OEM&width=300&pagesize=1&pagenum=1`;
      const stylePath = `styles.${styleId}`;

      return Promise.all([context.resolveValue(stylePath), withMetrics(EdmundsAPI, context).fetch(url)])
        .then(
          results => results[1].json() // get response from fetchJson
        )
        .then(({ photos }) =>
          // TODO: TMT-462 - remove hardcode when questions about how to load image will be resolved.
          // Current API return nested objects, arrays so we should take photos[0] and then source[0].
          get(photos, '[0].sources[0].link.href', '')
        );
    },
  },
  {
    path: 'styles.{style}.ratings.count',
    resolve({ style }, context) {
      const stylePath = `styles.${style}`;
      return context
        .resolveValue(stylePath)
        .then(styleData => {
          const { makeSlug, modelSlug, year } = styleData;
          if (!hasAllParameters(makeSlug, modelSlug, year)) {
            throw new Error('Query has missing parameter');
          }

          const url = `/vehiclereviews/v3/${makeSlug}/${modelSlug}/${year}/ratings/count/?fmt=graph`;

          return withMetrics(EdmundsAPI, context).fetch(url);
        })
        .then(response => response.json())
        .then(parseConsumerRatings)
        .catch(() => EMPTY_CONSUMERS_RATING);
    },
  },
  /**
   * @see buildNewTmvPricingPath
   */
  {
    path: 'styles.{styleId}.pricing.newTmv.withoutOptions',
    resolve({ styleId }, context) {
      return context
        .resolveValue('location.zipCode', VisitorModel)
        .then(zipCode =>
          withMetrics(EdmundsAPI, context).fetchJson(
            `/newtmv/v3/calculate?styleid=${styleId}&zip=${zipCode}&typical=true`
          )
        )
        .then(({ results: { tmv } }) => tmv)
        .catch(() => null);
    },
  },
  /**
   * Example: https://qa-21-www.edmunds.com/api/newtmv/v4/corepercent?styleid=401852745&zip=90404
   * @see buildNewTmvAdjustmentPath
   * @returns {NewTmvAdjustment}
   */
  {
    path: 'styles.{styleId}.pricing.newTmv.adjustment',
    resolve({ styleId }, context) {
      return context
        .resolveValue('location.zipCode', VisitorModel)
        .then(zipCode =>
          withMetrics(EdmundsAPI, context).fetchJson(`/newtmv/v4/corepercent?styleid=${styleId}&zip=${zipCode}`)
        )
        .then(adjustment => adjustment)
        .catch(() => ({}));
    },
  },
  /**
   * Example: https://qa-21-www.edmunds.com/api/v2/usedtmv/getalltmvbands?styleid=401640309&zipcode=99780&typical=false&mileage=180000&view=full&priceband=false
   * @returns StylePricing.usedTmv.withoutOptions
   */
  {
    path: 'styles.{styleId}.pricing.usedTmv.withoutOptions',
    async resolve({ styleId }, context) {
      let response;

      try {
        const [mileage, zipCode] = await Promise.all([
          context.resolveValue('estimatedAppraisalMileage'),
          context.resolveValue('location.zipCode', VisitorModel),
        ]);

        const url = buildAllTmvBandsUrl({ styleId, zipCode, mileage });
        response = await withMetrics(EdmundsAPI, context).fetchJson(url);
      } catch (ex) {
        response = {
          tmvconditions: {},
        };
      }

      return response.tmvconditions;
    },
  },
  /**
   * Example: https://qa-21-www.edmunds.com/api/newtmv/v4/transactioncount?styleid=401799395&dma=803
   * @see buildTransactionCountPath
   * @returns StyleTransactionCount
   */
  {
    path: 'styles.{styleId}.transactionCount',
    async resolve({ styleId }, context) {
      const { dma } = await context.resolveValue('location', VisitorModel);

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/newtmv/v4/transactioncount?styleid=${styleId}&dma=${dma}`)
        .catch(() => null);
    },
  },
  {
    path: 'styles.{styleId}.pricing.estimatedPricing',
    resolve({ styleId }, context) {
      return context
        .resolveValue('location.zipCode', VisitorModel)
        .then(zip =>
          withMetrics(EdmundsAPI, context).fetchJson(`/inventory/v5/estimated-savings/${styleId}?zip=${zip}`)
        )
        .then(({ response }) => response);
    },
  },
  /**
   * @see buildVehicleStyleInsurancePricesPath
   */
  {
    path: 'styles.{style}.insurancePrices',
    resolve({ style }, context) {
      return context
        .resolveValue('location', VisitorModel)
        .then(location => {
          const { stateCode } = location;
          const url = `/tco/v3/insurance?styleid=${style}&statecode=${stateCode}`;
          return withMetrics(EdmundsAPI, context).fetchJson(url);
        })
        .then(({ results }) => results);
    },
  },
  {
    path: 'styles["{styleId}"].squishVin',
    resolve({ styleId }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/vehicle/v3/styles?id=${styleId}&distinct=squishVins.squishVin`)
        .then(results => results);
    },
  },
  {
    path: 'styles.{style}.fiveyearstco',
    resolve({ style }, context) {
      return context
        .resolveValue('location', VisitorModel)
        .then(location => {
          const { zipCode } = location;
          const url = `/tco/v3/styles/${style}/zips/${zipCode}/fiveyearstco`;
          return withMetrics(EdmundsAPI, context).fetchJson(url);
        })
        .then(({ results }) => results)
        .catch(() => EMPTY_FIVE_YEARS_TCO);
    },
  },
  /**
   * @see buildEvTestedDataPath
   * @return [StyleEvTestedData]
   */
  {
    path: 'styles.{styleIds}.evTestedData',
    resolve({ styleIds }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($styleIds: [String!]!) {
              edmundsTestedEVData(styleId: $styleIds) {
                styleId
                testedStyleNameOverride
                range
                rangeAverageByBodyType
                consumptionKWHPer100Mi
                consumptionAvgByBodyTypeKWHPer100Mi
                totalChargingTimeMMSS
              }
            }
          `,
          {
            styleIds: styleIds.split(','),
          }
        )
        .then(response => response.edmundsTestedEVData)
        .catch(() => []);
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/api/tco/v3/fuelCosts/401745740?stateCode=CA&milesPerYear=30000&cityMilesPercent=55&dailyCommute=25
   * @see buildFuelCostsPath
   * @return StyleFuelCostData
   */
  {
    path:
      'styles.{styleId}.fuelCosts.milesPerYear["{milesPerYear}"].cityPercents["{cityPercents}"].dailyCommute["{dailyCommute}"].data',
    async resolve({ styleId, milesPerYear, dailyCommute, cityPercents }, context) {
      const visitorLocation = await context.resolveValue('location', VisitorModel);
      const stateCode = isUnknownZip(visitorLocation) ? 'US' : visitorLocation.stateCode;
      const query = objectToQueryString({
        stateCode,
        milesPerYear: milesPerYear === DEFAULT ? undefined : milesPerYear,
        cityMilesPercent: cityPercents === DEFAULT ? undefined : cityPercents,
        dailyCommute: dailyCommute === DEFAULT ? undefined : dailyCommute,
      });

      return withMetrics(EdmundsAPI, context)
        .fetchJson(`/tco/v3/fuelCosts/${styleId}?${query}`)
        .then(response => get(response, 'results', {}))
        .catch(() => ({}));
    },
  },
  /**
   * @return PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity)
   *
   * path: buildPartialFeaturesPath
   * api: https://qa-21-www.edmunds.com/gateway/api/vehicle/v5/makes/honda/models/civic/submodels/sedan/years/2021/styles/features-specs?pageNum=1&pageSize=50&sortby=price:asc&fields=features.standard.Drive%20Train,features.standard.Engine,features.standard.Fuel,features.standard.Measurements,features.standard.Warranty,price.baseMSRP,totalSeating,styleEndDate
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].stylesPartialFeaturesSpecs',
    resolve({ make, model, year, submodel }, context) {
      const url = `/vehicle/v5/makes/${make}/models/${model}/submodels/${submodel}/years/${year}/styles/features-specs?pageNum=1&pageSize=50&sortby=price:asc&fields=${FEATURES_SUBSET}`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => get(response, 'results', []));
    },
  },
  /**
   * Example: https://qa-21-www.edmunds.com/gateway/api/vehicle/v5/makes/honda/models/civic/submodels/sedan/years/2021/styles/features-specs?pageNum=1&pageSize=1&fields=styleAttributes
   * @see FeatureSpecsPaths.buildMmysStyleAttributesPath
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].styleAttributes',
    resolve({ make, model, year, submodel }, context) {
      const url = `/vehicle/v5/makes/${make}/models/${model}/submodels/${submodel}/years/${year}/styles/features-specs?pageNum=1&pageSize=1&fields=styleAttributes`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => get(response, 'results[0].styleAttributes'));
    },
  },
  /**
   * Takes a styleId and returns the corresponding features.
   *
   * path: FeatureSpecsPaths.buildStyleWithFeaturesPath
   * api: https://qa-11-www.edmunds.com/api/vehicle/v4/styles/401741629/features-specs
   * @deprecated use buildVehicleStylePath instead
   */
  {
    path: 'styles["{styleId}"]',
    resolve({ styleId }, context) {
      const url = `/vehicle/v4/styles/${styleId}/features-specs`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => {
          if (response && response.results) {
            return response.results; // this will be a single style (not an array)
          }
          return null;
        });
    },
  },
  /**
   * Takes a styleId and returns the corresponding features.
   *
   * path: FeatureSpecsPaths.buildStyleWithFeaturesPath
   * api: https://qa-11-www.edmunds.com/api/vehicle/v4/styles/401741629/features-specs
   * @deprecated use FeatureSpecsPaths.buildStyleFeaturesPath instead
   */
  {
    path: 'stylesWithFeatures["{styleId}"]',
    async resolve({ styleId }, context) {
      const style = await context.resolveValue(`styles["${styleId}"]`);
      if (style && style.features) {
        return { $ref: `#/styles/${styleId}` };
      }

      const response = await withMetrics(EdmundsAPI, context).fetchJson(`/vehicle/v4/styles/${styleId}/features-specs`);
      if (response && response.results && response.results) {
        await Promise.all([
          context.updateValue(`styles["${styleId}"].color`, response.results.color),
          context.updateValue(`styles["${styleId}"].totalSeating`, response.results.totalSeating),
          context.updateValue(`styles["${styleId}"].features`, response.results.features),
        ]);
      }

      return { $ref: `#/styles/${styleId}` };
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/api/vehicle/v4/styles/401741629/features-specs
   * @see FeatureSpecsPaths.buildStyleFeaturesPath
   */
  {
    path: 'features.styles["{styleId}"]',
    resolve({ styleId }, context) {
      const url = `/vehicle/v5/styles/${styleId}/features-specs`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => get(response, 'results'))
        .then(style => ({
          ...style,
          isElectric:
            get(
              get(style && style.orderedFeatures.find(({ category }) => category === 'Fuel'), 'features', []).find(
                ({ name }) => name === 'Fuel type'
              ),
              'value',
              ''
            ).toLowerCase() === ELECTRIC_FUEL_TYPE,
        }));
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/api/vehicle/v5/styles/401741629/features-specs?fields=styleAttributes
   * @see FeatureSpecsPaths.buildStyleAttributesPath
   */
  {
    path: 'features.styleAttributes.styles["{styleId}"]',
    resolve({ styleId }, context) {
      const url = `/vehicle/v5/styles/${styleId}/features-specs?fields=styleAttributes`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => get(response, 'results.styleAttributes'));
    },
  },
  /**
   * Example: https://www.edmunds.com/gateway/api/vehicle/v3/modelYears?makeNiceId=toyota&publicationStates=NEW,NEW_USED&fields=makeName,makeNiceId,modelName,modelNiceId,year,publicationStates,types.engineType&sortby=modelNiceName%3AASC&pageSize=1000&pageNum=1
   * @see buildModelYearsPath
   */
  {
    path: 'makes["{make}"].modelYears',
    resolve({ make }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${make}&publicationStates=NEW,NEW_USED&fields=makeName,makeNiceId,modelName,modelNiceId,year,publicationStates,types.engineType&sortby=modelNiceName%3AASC&pageSize=1000&pageNum=1`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(
          ({ results }) =>
            results &&
            results.map(model => ({
              ...model,
              makeSlug: model.makeNiceId,
              modelSlug: model.modelNiceId,
              engineTypes: get(model, 'types.engineType', []),
              makeNiceId: undefined,
              modelNiceId: undefined,
              types: undefined,
            }))
        );
    },
  },
  /**
   * https://qa-11-www.edmunds.com/api/vehicle/v3/modelYears?makeNiceName=honda&modelNiceName=accord&year=2019&fields=newDefaultStyle.id
   * @returns {Object}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].baseStyle',
    resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&modelNiceId=${modelSlug}&year=${year}&fields=newDefaultStyle.id`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results && results[0]);
    },
  },
  /**
   * https://qa-11-www.edmunds.com/api/vehicle/v3/modelYears?makeNiceName=honda&modelNiceName=accord&year=2019&fields=usedDefaultStyle.id
   * @returns {Object}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].usedBaseStyle',
    async resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&modelNiceId=${modelSlug}&year=${year}&fields=usedDefaultStyle.id`;

      let results;
      try {
        const response = await withMetrics(EdmundsAPI, context).fetchJson(url);
        results = response.results && (response.results[0] || {});
      } catch (e) {
        results = {};
      }

      return results;
    },
  },
  /**
   * Example: https://qa-11-www.edmunds.com/gateway/api/vehicle/v3/honda/civic/2023/consumergenericfeatures
   * @see buildMMYGenericFeaturesPath
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].genericFeatures',
    resolve({ make, model, year, submodel }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `/vehicle/v3/${make}/${model}/${year}/consumergenericfeatures${
            submodel === DEFAULT ? '' : `?submodel=${submodel}`
          }`
        )
        .then(result => result);
    },
  },
  /**
   * Example: https://qa-21-www.edmunds.com/gateway/api/coreresearch/v2/defaultyear/honda/civic
   * @see buildMakeModelDefaultYear
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].defaultYear',
    async resolve({ makeSlug, modelSlug }, context) {
      const url = await getDefaultYearUrl({ context, makeSlug, modelSlug });

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(years => {
          if (!years) {
            return null;
          }

          return get(years, 'results.year') || get(years, 'results.defaultYear');
        });
    },
  },
  /**
   * TODO: Remove when flag is moved to vehicle/v4
   * Example: https://qa-21-www.edmunds.com/gateway/api/vehicle/v3/modelYears?makeNiceId=cadillac&modelNiceId=ct4-v-blackwing&year=2022&fields=attributeGroups.MAIN.properties.MINIMUM_VIABLE_DATA
   * @see buildMakeModelIsMinimalViableDataPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].isMinimalViableData',
    resolve({ makeSlug, modelSlug, year }, context) {
      return withMetrics(EdmundsAPI, context)
        .fetchJson(
          `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&modelNiceId=${modelSlug}&year=${year}&fields=attributeGroups.MAIN.properties.MINIMUM_VIABLE_DATA`
        )
        .then(response => get(response, 'results[0].attributeGroups.MAIN.properties.MINIMUM_VIABLE_DATA') === 'Y');
    },
  },
]);

const ColorEntity = PropTypes.shape({
  name: PropTypes.string.isRequired, // ex: "Champagne Frost Pearl"
  rgb: PropTypes.string, // ex: "200,167,124"
});

const Feature = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  price: PropTypes.number,
});

const StyleAttributes = PropTypes.shape({
  electric: PropTypes.bool,
  pluginElectric: PropTypes.bool,
  truck: PropTypes.bool,
});

const FeatureStyleEntity = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  price: PropTypes.shape({
    baseMsrp: PropTypes.number,
    baseInvoice: PropTypes.number,
  }),
  color: PropTypes.shape({
    EXTERIOR: PropTypes.arrayOf(ColorEntity),
    INTERIOR: PropTypes.arrayOf(ColorEntity),
  }),
  features: PropTypes.oneOfType([
    PropTypes.shape({
      standard: PropTypes.objectOf(PropTypes.arrayOf(Feature)),
      optional: PropTypes.objectOf(PropTypes.arrayOf(Feature)),
    }),
    PropTypes.objectOf(PropTypes.arrayOf(Feature)),
  ]),
  orderedFeatures: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      categoryGroup: PropTypes.string,
      features: PropTypes.arrayOf(Feature),
    })
  ),
  styleAttributes: StyleAttributes,
  totalSeating: PropTypes.number,
  isElectric: PropTypes.bool,
  styleEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const FeatureSpecsEntities = {
  ColorEntity,
  FeatureStyleEntity,
  Feature,
  StyleAttributes,
};
