import debounce from 'lodash/debounce';
import { EventToolbox } from 'client/utils/event-toolbox';
import { PAGE_EVENTS } from 'client/constants/page-events';
import { IntersectionObserver } from 'client/utils/intersection-observer';
import { PAGE_CONTENT_UPDATE } from 'client/tracking/constant';
import {
  refreshAdsOnScroll,
  scrollStepAdsRefresher,
} from 'client/site-modules/shared/components/ad-unit/utils/scroll-ads-refresher';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';
import {
  getEnableRefreshAds,
  getDelayTime,
  DELAY_TIME,
} from 'client/engagement-handlers/adhesion-ad-refresh-rate-limiter';

/**
 * Scroll engagement handlers
 */

let shouldRefreshAdUnits = false;
const scrollPxRefreshActionAwait = 1000;
const setInviewListenersAwait = 1000;
const TRIGGER = 'scroll';

// for debug purpose
export function resetShouldRefreshAds() {
  shouldRefreshAdUnits = false;
}

export const ScrollEngagementHandler = {
  /**
   * Setups scroll events listener
   *
   * @param  {Function} dispatch Dispatch app redux store actions
   * @return {void}
   */
  init({ dispatch, getState }) {
    let observer = null;
    const config = {
      rootMargin: '0px',
    };
    const state = getState();

    const adsEnabled = state.featureFlags.ads;
    // TODO: Clean up anything related to this once done
    // https://edmunds.atlassian.net/browse/PLAT-1719
    const moveWidgetRolloverToGtm = state.featureFlags.combineEDW;
    let widgetViews;

    const combineEDWCampaignValue = ExperimentUtil.getForcedOrAssignedRecipeName({
      state,
      campaignName: 'PLAT-1719',
    });

    if (combineEDWCampaignValue === 'chal' && moveWidgetRolloverToGtm) {
      widgetViews = new Map(); // using a Map to preserve insertion order (for debugging purposes)
    } else {
      widgetViews = new Set(); // using a Set to avoid duplicates, just in case
    }

    /**
     * trackWidgetView()
     * fires tracking pixels for widgets in view
     *
     * reason this function is debounced for 100ms is so that it only gets called for widgets that have been in view
     * for more than 100ms. For example, if one scrolls very fast from top of the page to the bottom (e.g. ctrl+end)
     * and say at the end of the scroll only one widget is visible (e.g. footer) the track will fire just for that
     * and not for anything in the middle that came in and out of view during the scroll.
     *
     * caller of this function should set widgetViews global var as a way to pass info to this function. Why this
     * way instead of passing as a traditional param? Consider this example
     * 1. user presses page down
     * 2. at the end of the scroll two widgets are in view
     * if this function took params as function arguments then the debounced callback would trigger only for the
     * last widget.
     */
    const trackWidgetViewPLAT1588 = debounce(() => {
      widgetViews.forEach((widget, creativeId) => {
        const { boundingClientRect } = widget;
        const pageHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);
        const widgetHeight = boundingClientRect.height;
        // scrollY officially is not supported in iE9. Fallback is pageYOffset.
        // getBoundingClientRect is supported in iE9. See http://caniuse.com/#feat=getboundingclientrect
        const widgetOffset = boundingClientRect.top + (window.scrollY || window.pageYOffset);
        const trackingData = {
          event_type: 'widget_view',
          event_data: {
            action_name: 'widget_view',
            action_cause: 'scroll',
            creative_id: creativeId,
            total_page_height: pageHeight,
            widget_height: widgetHeight,
            widget_y_position: widgetOffset,
          },
        };
        EventToolbox.fireCustomEvent('trackAction', trackingData);
      });
      widgetViews.clear();
    }, 100);

    /**
     * trackWidgetView()
     * fires tracking pixel for widgets in view
     *
     * reason this function is debounced for 100ms is so that it only gets called for widgets that have been in view
     * for more than 100ms. For example, if one scrolls very fast from top of the page to the bottom (e.g. ctrl+end)
     * and say at the end of the scroll only one widget is visible (e.g. footer) the track will fire just for that
     * and not for anything in the middle that came in and out of view during the scroll. If there was more than one
     * widget in view, one track will fire for all those widgets in view.
     *
     * caller of this function should set widgetViews global var as a way to pass info to this function. Why this
     * way instead of passing as a traditional param? Consider this example
     * 1. user presses page down
     * 2. at the end of the scroll two widgets are in view
     * if this function took params as function arguments then the debounced callback would trigger only for the
     * last widget.
     */
    const trackWidgetView = debounce(() => {
      if (!widgetViews.size) {
        return;
      }

      const pageHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight);

      const trackingData = {
        event_type: 'widget_view',
        event_data: {
          action_name: 'widget_view',
          action_cause: 'scroll',
          total_page_height: pageHeight,
          creative_id: Array.from(widgetViews).join(','),
        },
      };

      EventToolbox.fireCustomEvent('trackAction', trackingData);
      widgetViews.clear();
    }, 100);

    const doAdsRefresh = () => {
      if (shouldRefreshAdUnits) {
        refreshAdsOnScroll(dispatch, getState, TRIGGER);
      }
      // Enable ads refresh after first widget views
      // Set timeout here to handle multiple initial widget views for multiple modules
      setTimeout(() => {
        shouldRefreshAdUnits = true;
      }, 100);
    };

    const refreshObservedNodes = () => {
      if (observer) {
        observer.disconnect();

        const elements = document.querySelectorAll(
          '[data-tracking-parent]:not(.modal-drawer):not(.global-navigation):not(.aunit):not([data-no-widget-view])'
        );

        // NodeList.forEach is not supported in IE
        Array.prototype.forEach.call(elements, element => {
          observer.observe(element);
        });
      }
    };

    const setInviewListenersPLAT1588 = (preventAdsRefreshing = false) => {
      setTimeout(() => {
        if (IntersectionObserver) {
          observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              const creativeId = entry.target.getAttribute('data-tracking-parent');
              if (entry.isIntersecting) {
                widgetViews.set(creativeId, entry);
              } else {
                // for example if one presses Cmd+ArrDown to go from top to bottom of the page
                // this callback will be called twice for every widget in the middle, once
                // as widget enter into view, and once as it leaves the view.
                // but the debounced trackWidgetView() will be called only once and at that time
                // the widgetViews will have the correct items
                widgetViews.delete(creativeId);
              }
            });
            trackWidgetViewPLAT1588();
            if (!preventAdsRefreshing && adsEnabled) {
              doAdsRefresh(); // action itself is debounced internally so not debouncing again in this file
            }
          }, config);

          refreshObservedNodes();
        }
      }, setInviewListenersAwait);
    };

    // Intersection Observer setter
    const setInviewListeners = (preventAdsRefreshing = false) => {
      setTimeout(() => {
        if (IntersectionObserver) {
          observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              const creativeId = entry.target.getAttribute('data-tracking-parent');
              if (entry.isIntersecting) {
                widgetViews.add(creativeId);
              } else {
                // for example if one presses Cmd+ArrDown to go from top to bottom of the page
                // this callback will be called twice for every widget in the middle, once
                // as widget enter into view, and once as it leaves the view.
                // but the debounced trackWidgetView() will be called only once and at that time
                // the widgetViews will have the correct items
                widgetViews.delete(creativeId);
              }
            });
            trackWidgetView();
            if (!preventAdsRefreshing && adsEnabled) {
              doAdsRefresh(); // action itself is debounced internally so not debouncing again in this file
            }
          }, config);

          refreshObservedNodes();
        }
      }, setInviewListenersAwait);
    };
    // Scroll breakpoint listener setter
    let removeScrollPxListener;
    let lastRefresh;
    const setScrollPxBreakpointsListener = () => {
      const delayTime = getDelayTime(DELAY_TIME, scrollPxRefreshActionAwait + setInviewListenersAwait);
      const scrollPxRefreshAction = debounce(() => {
        const enableFireCustomEvent = getEnableRefreshAds(new Date().getTime(), lastRefresh, delayTime);

        if (enableFireCustomEvent) {
          const step = window.scrollY || window.pageYOffset;

          scrollStepAdsRefresher(getState, step, TRIGGER);
          lastRefresh = new Date().getTime();
        }
      }, scrollPxRefreshActionAwait);

      EventToolbox.on('scroll', scrollPxRefreshAction, true);
      return () => {
        EventToolbox.off('scroll', scrollPxRefreshAction, true);
      };
    };

    /**
     * Scroll spy init
     */
    if (combineEDWCampaignValue === 'chal' && moveWidgetRolloverToGtm) {
      EventToolbox.on(PAGE_EVENTS.PAGE_LOAD, ({ detail: { options } }) => {
        const disableAdRefreshOnScroll = options && options.disableAdRefreshOnScroll;

        removeScrollPxListener = setScrollPxBreakpointsListener(options);
        setInviewListenersPLAT1588(disableAdRefreshOnScroll);
      });
    } else {
      EventToolbox.on(PAGE_EVENTS.PAGE_LOAD, ({ detail: { options } }) => {
        const disableAdRefreshOnScroll = options && options.disableAdRefreshOnScroll;

        removeScrollPxListener = setScrollPxBreakpointsListener(options);
        setInviewListeners(disableAdRefreshOnScroll);
      });
    }

    EventToolbox.on(PAGE_EVENTS.PAGE_UNLOAD, () => {
      if (observer) {
        observer.disconnect();
      }
      if (removeScrollPxListener) {
        removeScrollPxListener();
        removeScrollPxListener = null;
      }
    });

    EventToolbox.on(PAGE_CONTENT_UPDATE, refreshObservedNodes);
  },
};
