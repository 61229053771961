import { get } from 'lodash';
import {
  RESEARCH_ARTICLE_SLUGS,
  RESEARCH_ARTICLE_LANDING_SLUGS,
  RESKINNED_RESEARCH_ARTICLE_SLUGS,
  BEST_OF_SLUGS,
  RESEARCH,
  NEW_CAR_RATINGS,
  RESEARCH_ARTICLE_SLUGS_FOR_MULTILEVEL_CATEGORY_LANDING,
} from 'site-modules/editorial/constants/slugs';
import { ONE_COLUMN_TEMPLATE_RESEARCH_ARTICLE_IDS } from 'client/site-modules/editorial/constants/research-article';
import { CmsModel } from 'client/data/models/cms';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { isBestContent } from 'site-modules/editorial/utils/best';
import { parseContent } from 'client/data/cms/content';
import { getResearchArticleIndexPath } from 'site-modules/editorial/utils/content-path';
import { isCarNews as isCarNewsCategory } from 'site-modules/editorial/utils/car-news';

/**
 * route for each vehicle type
 * @see https://edmundswiki.atlassian.net/wiki/spaces/CP/pages/2041676136/Research-article+routing (More explanation)
 * @type {Array}
 */
export const researchArticlesRoutes = [
  /**
   * Route for editorial research landing page with updated atom files, e.g.
   * https://www.edmunds.com/car-buying/
   */
  {
    path: `/:articleCategory(${[...RESKINNED_RESEARCH_ARTICLE_SLUGS, RESEARCH].join('|')})`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "research-category-landing" */ '../pages/research-category/research-category-landing-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'research-category-landing',
  },

  /**
   * Route for research articles with one-column and no-hero template
   * https://www.edmunds.com/car-buying/best-used-cars-to-buy-right-now.html
   */
  {
    path: `/:articleCategory(${RESKINNED_RESEARCH_ARTICLE_SLUGS.join(
      '|'
    )})/:articleId(${ONE_COLUMN_TEMPLATE_RESEARCH_ARTICLE_IDS.join('|')}).html`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "one-column-no-hero-article" */ '../pages/research-article/one-column-no-hero-article/one-column-no-hero-article-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'one-column-no-hero-article',
  },

  /**
   * Route for single research article page, e.g.
   * https://www.edmunds.com/car-buying/a-car-shoppers-guide-to-auto-shows.html
   * Route for multi-level directory article page, e.g.
   * https://www.edmunds.com/car-news/first-impressions/2019-porsche-macan-first-look.html
   */
  {
    path: [
      `/:articleCategory(${[...RESEARCH_ARTICLE_SLUGS, ...RESKINNED_RESEARCH_ARTICLE_SLUGS].join(
        '|'
      )})/:splat*/:articleId.:ext`,
      '/:articleCategory(car-buying)/:splat(dealer-holdback)/', // /car-buying/dealer-holdback/ is single url exclusion
    ],
    exact: true,
    preload: ({ articleCategory, splat, articleId = 'index' }, store) => {
      const preloader = new ModelPreloader(store);

      preloader.resolve(`content["${getResearchArticleIndexPath(articleCategory, articleId, splat)}"]`, CmsModel);

      return preloader.load();
    },
    page: getLoadablePage(({ articleCategory, splat, articleId = 'index', ext }, store) => {
      const modelState = getModelState(store.getState());
      const pageParams = { articleCategory, splat, articleId, ext };
      const content = parseContent(
        modelState.get(`content["${getResearchArticleIndexPath(articleCategory, articleId, splat)}"]`, CmsModel)
      );

      if (isBestContent(get(content, 'id'))) {
        return import(/* webpackChunkName: "research-best-article" */ '../pages/research-article/research-best/research-best-definition').then(
          page => page.default({ ...pageParams, chunkName: 'research-best-article' })
        );
      }

      if (content.metadata('oneColumnTemplate').boolean()) {
        return import(/* webpackChunkName: "research-one-column-article" */ '../pages/research-article/research-one-column/research-one-column-definition').then(
          page => page.default({ ...pageParams, chunkName: 'research-one-column-article' })
        );
      }

      const heroPhoto = get(content.child('hero-content').links(), [0], '');
      if (isCarNewsCategory(articleCategory) && heroPhoto) {
        return import(/* webpackChunkName: "research-type-article" */ '../pages/research-article/research-type/research-type-definition').then(
          page => page.default({ ...pageParams, chunkName: 'research-type-article' })
        );
      }

      return import(/* webpackChunkName: "research-default-article" */ '../pages/research-article/research-default/research-default-definition').then(
        page => page.default({ ...pageParams, chunkName: 'research-default-article' })
      );
    }),
  },

  /**
   * Route for multi-level editorial research landing page, e.g.
   * https://www.edmunds.com/car-reviews/long-term-road-tests/
   */
  {
    path: `/:articleCategory(${RESEARCH_ARTICLE_SLUGS_FOR_MULTILEVEL_CATEGORY_LANDING.join('|')})/:splat`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "research-category-landing" */ '../pages/research-category/research-category-landing-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'research-category-landing',
  },

  /**
   * Route for multi-level directory category page with updated atom files for landing pages, e.g.
   * https://www.edmunds.com/car-news/first-impressions/
   */
  {
    path: `/:articleCategory(${[...RESKINNED_RESEARCH_ARTICLE_SLUGS, ...RESEARCH_ARTICLE_SLUGS].join('|')})/:splat`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "research-category" */ '../pages/research-category-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'research-category',
  },

  /**
   * Route for editorial research landing page with not updated atom files, e.g.
   * https://www.edmunds.com/car-buying/
   */
  {
    path: `/:articleCategory(${RESEARCH_ARTICLE_LANDING_SLUGS.join('|')})`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "research-category" */ '../pages/research-category-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'research-category',
  },

  /**
   * Route for "best-of" article page, e.g.
   * https://www.edmunds.com/best-used-cars/
   */
  {
    path: `/:bestOfCategory(${BEST_OF_SLUGS.join('|')})`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "best-of-article" */ '../pages/research-article/best-of-article/best-of-article-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'best-of-article',
  },

  /**
   * Route for "best-of" multi-level article page, e.g.
   * https://www.edmunds.com/best-used-cars/blah/
   */
  {
    path: `/:bestOfCategory(${BEST_OF_SLUGS.join('|')})/:articleId`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "best-of-article" */ '../pages/research-article/best-of-article/best-of-article-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'best-of-article',
  },

  /**
   * Disable critical CSS for a heavy page.
   * Details: https://edmunds.atlassian.net/browse/SEOT-1040
   */
  {
    path: `/:bestOfCategory(${NEW_CAR_RATINGS})`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "best-of-article" */ '../pages/research-article/best-of-article/best-of-article-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'best-of-article',
    inlineCritical: false,
  },
];
