export const TrackingConstant = {
  /**
   * Attribute for elements to make themselves trackable and bind to appropriate tracking
   */
  DATA_TRACKING_ID_ATTRIBUTE: 'data-tracking-id',

  /**
   * Attribute for elements to store value that's being used when tracking fires
   */
  DATA_TRACKING_VALUE_ATTRIBUTE: 'data-tracking-value',

  EVENT_TYPE_WIDGET_VIEW: 'widget_view',

  /**
   * Action names
   */
  ACTION_CAPTURE_3RD_PARTY_INFO: 'capture_3rd_party_info',
  ACTION_AUTHENTICATE: 'authenticate',
  ACTION_VIEW_REVIEW: 'view_review',
  ACTION_VIEW_REVIEWS: 'view_reviews',
  ACTION_VIEW_MORE_REVIEWS: 'view_more_reviews',
  ACTION_VIEW_REVIEW_DETAILS: 'view_review_details',
  ACTION_VIEW_CONTENT: 'view_content',
  ACTION_EMBED_CONTENT: 'embed_content',
  ACTION_VIEW_ALL: 'view_all',
  ACTION_VIEW_QUOTES: 'view_quotes',
  ACTION_MM_SELECT: 'mm_select',
  ACTION_MMY_SELECT: 'mmy_select',
  ACTION_MMZ_SELECT: 'mmz_select',
  ACTION_INVENTORY_SELECT: 'inventory_select',
  ACTION_TOOL_SELECT: 'tool_select',
  ACTION_TOOL_PROVIDE_USER_FEEDBACK: 'provide_user_feedback',
  ACTION_TYPE_SELECT: 'type_select',
  ACTION_MAKE_SELECT: 'make_select',
  ACTION_PRICE_RANGE_SELECT: 'price_range_select',
  ACTION_LOAN_PAYMENT_SELECT: 'loan_payment_select',
  ACTION_LEASE_PAYMENT_SELECT: 'lease_payment_select',
  ACTION_VIEW_PRICING_DETAILS: 'view_pricing_details',
  ACTION_PROVIDE_ZIP_CODE: 'provide_zip_code',
  ACTION_VIEW_VIN_DETAILS: 'view_vin_details',
  ACTION_RESET_NAVIGATION: 'reset_navigation',
  ACTION_DEALER_ENGAGEMENT: 'dealer_engagement',
  ACTION_WEBSITE_CLICKOUT: 'website_clickout',
  ACTION_WRITE_REVIEW: 'write_review',
  ACTION_SIGN_UP: 'sign_up',
  ACTION_SIGN_IN: 'sign_in',
  ACTION_SIGN_IN_PASSWORD: 'sign_in_password',
  ACTION_SIGN_IN_LINK: 'sign_in_link',
  ACTION_SIGN_OUT: 'sign_out',
  ACTION_UPDATE_MEMBERSHIP: 'update_membership',
  ACTION_SHOW_CONTENT: 'show_content',
  ACTION_SHOW_HISTORY: 'show_history',
  ACTION_SHOW_LINK: 'show_link',
  ACTION_PURCHASE_VEHICLE: 'purchase_vehicle',
  DEAL_ACTIVATION: 'deal_activation',
  ACTION_LEAD_SUBMISSION: 'lead_submission',
  ACTION_VIEW_PHOTO: 'view_photo',
  ACTION_WIDGET_VIEW: 'widget_view',
  ACTION_APPRAISAL_RETRIEVAL: 'appraisal_retrieval',
  ACTION_APPRAISE_VEHICLE: 'appraise_vehicle',
  ACTION_CAUSE_USER_INPUT: 'user_input',
  ACTION_CAUSE_DRAWER_OPEN: 'drawer_open',
  ACTION_CAUSE_MENU_OPEN: 'menu_open',
  ACTION_CAUSE_LINK_CLICK: 'link_click',
  ACTION_CAUSE_SWIPE: 'swipe',
  ACTION_CAUSE_BUTTON_CLICK: 'button_click',
  ACTION_CAUSE_CLICK: 'click',
  ACTION_CAUSE_SCROLL: 'scroll',
  ACTION_CAUSE_DRAG: 'drag',
  ACTION_SELECT_DEALERSHIP: 'select_dealership',
  ACTION_UNSELECT_DEALERSHIP: 'unselect_dealership',
  ACTION_INVENTORY_SEARCH_FILTER: 'inventory_view_filter_search_result',
  ACTION_INVENTORY_SEARCH_FILTER_REMOVE: 'inventory_remove_filter_search_result',
  ACTION_LIST_VIEWED: 'list_viewed',
  ACTION_OPEN_BUILD_PANEL: 'open_build_panel',
  ACTION_ENGAGE_SURVEY: 'engage_survey',
  ACTION_CONFIGURE_VEHICLE: 'configure_vehicle',
  ACTION_WHY_EDMUNDS: 'why_edmunds',
  ACTION_SAVE_VEHICLE: 'save_vehicle',
  ACTION_REVIEW_PURCHASE_DEAL: 'review_purchase_deal',
  ACTION_PRINT_PAGE: 'print_page',
  ACTION_GET_HELP: 'get_help',
  ACTION_CLOSE: 'close',
  ACTION_RECEIVE_EPO: 'receive_epo',
  ACTION_EPO_OFFER_DELTA: 'epo_offer_delta',
  ACTION_LIST: 'list',
  ACTION_SHARE_CONTENT: 'share_content',
  ACTION_SAVING_RETRIEVAL: 'saving_retrieval',
  ACTION_CAUSE_HOVER: 'hover',
  ACTION_SUBMIT_SENTIMENT: 'submit_sentiment',
  ACTION_MODS_RETURNED: 'mods_record_returned',
  ACTION_QUOTE_RECENCY: 'quote_recency_returned',
  ACTION_EPO_OFFER_STATUS: 'epo_offer_status',
  ACTION_RENEW_OFFER: 'renew_offer',
  ACTION_FILL_FORM: 'fill_form',

  /**
   * Action categories
   */
  USER_ACTION_CATEGORY: 'user',
  SYSTEM_ACTION_CATEGORY: 'system',

  /**
   * Action causes
   */
  PAGE_LOAD_CAUSE: 'page_load',
  SYSTEM_ACTION_CAUSE: 'system',

  /**
   * Event types
   */
  EVENT_TYPE_ACTION_START: 'action_start',
  EVENT_TYPE_ACTION_PROGRESS: 'action_progress',
  EVENT_TYPE_ACTION_END: 'action_end',
  EVENT_TYPE_ACTION_COMPLETED: 'action_completed',
  EVENT_TYPE_PAGE_ENTER: 'page_enter',

  /**
   * Subaction names
   */
  SELECT_OWN_VEHICLE: 'select_own_vehicle',
  REMOVE_OWN_VEHICLE: 'remove_own_vehicle',
  FIND_SIMILAR_VEHICLE: 'find_similar_vehicle',
  REMOVE_VEHICLE: 'remove_vehicle',
  ADD_VEHICLE: 'add_vehicle',
  CLOSE_FILTER_PANEL: 'close_filter_panel',
  OPEN_FILTER_PANEL: 'open_filter_panel',
  VIEW_FILTER_CONTENT: 'view_filter_content',
  DISPLAY_ORDER: 'display_order',
  CHANGE_VIEW: 'change_view',
  SHOP_WITH_PREQUALIFICATION: 'shop_with_prequalification',
  RATE_LOOKUP_SUCCESS_NO_VIN: 'rate_lookup-success-no_vin',
  RATE_LOOKUP_SUCCESS: 'rate_lookup-success',
  OFFER_INPUT_SUCCESS: 'offer_input_success',
  OFFER_INPUT_FAILURE: 'offer_input_failure',

  ACTION_VIEW_CPO_REQS: 'view_cpo_reqs',
  CHANGE_PERSONAL_INFO: 'change_personal_info',
  CHANGE_PASSWORD: 'change_password',
  CREATE_PASSWORD: 'create_password',
  UPDATE_EMAIL_SUBSCRIPTIONS: 'update_email_subscriptions',
  UDM_HIST: 'udm_hist',

  SUBMIT_MM: 'submit_mm',
  SUBMIT_MMY: 'submit_mmy',
  SUBMIT_MMZ: 'submit_mmz',
  SUBMIT_MMYT: 'submit_mmyt',
  SUBMIT_MMY_STYLE: 'submit_mmy_style',
  SUBMIT_MAKE: 'submit_make',
  SUBMIT_MODEL: 'submit_model',
  SUBMIT_MAKE_MODEL: 'submit_make_model',
  SUBMIT_VEHICLE_TYPE: 'submit_vehicle_type',
  SUBMIT_PRICE: 'submit_price',
  SUBMIT_LOAN_PAYMENT: 'submit_loan_payment',
  SUBMIT_LEASE_PAYMENT: 'submit_lease_payment',
  SUBMIT_DEALER: 'submit_dealer',
  SUBMIT_PRIMARY: 'submit_primary',
  SUBMIT_SECONDARY: 'submit_secondary',
  START_PRIVATE_PARTY_LISTING: 'start_private_party_listing',
  SUBMIT_ADDRESS_LOOKUP: 'submit_address_lookup',
  SUBMIT_ADDRESS_STATE: 'submit_address_state',
  ADDRESS_LOOKUP_ERROR: 'address_lookup_error',
  ADDRESS_LOOKUP: 'address_lookup',
  ADDRESS_LOOKUP_DISPLAY_RESULT: 'address_lookup_display_result',

  SCHEDULE_APPT_OPEN_CALENDAR: 'schedule_appt_open_calendar',
  SCHEDULE_APPT_CLOSE_CALENDAR: 'schedule_appt_close_calendar',
  SCHEDULE_APPT_OPEN_TIME_SLOTS: 'schedule_appt_open_time_slots',
  SCHEDULE_APPT_CLOSE_TIME_SLOTS: 'schedule_appt_close_time_slots',
  SCHEDULE_APPT_CHANGE_DATE: 'schedule_appt_change_date',
  SCHEDULE_APPT_CHANGE_TIME: 'schedule_appt_change_time',

  CUSTOMER_CONSENT_FOR_ADDRESS_LOOKUP: 'customer_consent_for_address_lookup',

  CLOSE_BANNER: 'close_banner',

  CAR_TYPES: 'car_types',
  CARS_FOR_SALE: 'cars_for_sale',
  RESEARCH: 'research',
  SHOPPER_FORUMS: 'shopper_forums',

  MAKE_SELECT: 'make_select',
  MODEL_SELECT: 'model_select',
  SUBMODEL_SELECT: 'submodel_select',
  YEAR_SELECT: 'year_select',
  TRIM_SELECT: 'trim_select',
  STYLE_SELECT: 'style_select',
  SELECT_TRIM: 'select_trim',
  EXPAND_TRIM: 'expand_trim',
  COLLAPSE_TRIM: 'collapse_trim',
  SELECT_CHANGE: 'select_change',
  PUBSTATE_SELECT: 'pubstate_select',
  MAKE_MODEL_SELECT: 'make_model_select',
  SELECT_MAKE_MODEL: 'select_make_model',
  VEHICLE_TYPE_SELECT: 'vehicle_type_select',
  VEHICLE_TYPE: 'vehicle_type',
  MIN_PRICE_SELECT: 'min_price_select',
  MAX_PRICE_SELECT: 'max_price_select',
  PRICE_RADIO_SELECT: 'price_radio_select',
  LOAN_PAYMENT_RADIO_SELECT: 'loan_payment_radio_select',
  LEASE_PAYMENT_RADIO_SELECT: 'lease_payment_radio_select',
  VIEW_LEASE_EVALUATION: 'view_lease_evaluation',
  APPLY_BUNDLE: 'apply_bundle',

  CHOOSE_MODEL_YEAR: 'choose_model_year',
  CHOOSE_VEHICLE_TYPE: 'choose_vehicle_type',
  ADD_OPTION: 'add_option',
  OPEN_SELECT_FROM_MODEL_LIST: 'open_select_from_model_list',
  SELECT_FROM_MODEL_LIST: 'select_from_model_list',
  OPEN_COMPARE_MODELS_WIDGET: 'open_compare_models_widget',
  SELECT_FROM_COMPARE_MODELS_WIDGET: 'select_from_compare_models_widget',
  COMPARE_DEALER_PRICES: 'compare_dealer_prices',
  CONFIRM_TYPE_SELECTION: 'confirm_type_selection',

  CHANGE_MODEL_DROPDOWN: 'change_model_dropdown',

  MPG_MODEL_SELECT: 'mpg_model_select',
  MPG_ZIP_CODE_ENTRY: 'mpg_zip_code_entry',
  MPG_MILES_PER_YEAR_ENTRY: 'mpg_miles_per_year_entry',
  MPG_DAILY_COMMUTE_ENTRY: 'mpg_daily_commute_entry',
  MPG_CITY_HIGHWAY_ENTRY: 'mpg_city_highway_entry',

  MILE_ONE_DISPLAYED: 'mile_one_displayed',

  TCO_MODEL_SELECT: 'tco_model_select',
  TCO_ZIP_CODE_ENTRY: 'tco_zip_code_entry',

  TAB_DEFAULT: 'tab_default',
  TAB_SELECT: 'tab_select',
  MAKE_MODEL_TAB_SELECT: 'make_model_tab_select',
  VEHICLE_TYPE_TAB_SELECT: 'vehicle_type_tab_select',
  PRICE_TAB_SELECT: 'price_tab_select',

  NEW_CAR_SELECT: 'new_car',
  USED_CAR_SELECT: 'used_car',
  NEW_USED_CAR_SELECT: 'new_used_select',

  VIEW_CONSUMER_REVIEW: 'view_consumer_review',
  VIEW_FEATURES_SPECS: 'view_features_specs',
  SEARCH_REVIEW_TOPIC: 'search_review_topic',
  VIEW_EDMUNDS_REVIEW: 'view_edmunds_review',
  VIEW_SEARCH_RESULT: 'view_search_result',
  VIEW_MORE_FOR_SALE: 'view_more_for_sale',
  VIEW_SUMMARY: 'view_summary',
  // TODO: review during cleanup of shop-2910-collapse-filters
  VIEW_FILTER_SECTION: 'view_filter_section',
  CLOSE_FILTER_SECTION: 'close_filter_section',
  VIEW_DETAILS: 'view_details',
  HIDE_DETAILS: 'hide_details',
  VIEW_MODELS: 'view_models',
  VIEW_MAKES: 'view_makes',
  VIEW_PRICING_DETAILS: 'view_pricing_details',
  VIEW_VEHICLE_DETAILS: 'view_vehicle_details',
  VIEW_VEHICLE_CARD: 'view_vehicle_card',
  VIEW_VEHICLE_RANKING: 'view_vehicle_ranking',
  VIEW_VIN_HIGHLIGHTS: 'view_vin_highlights',
  VIEW_LEAD_FORM: 'view_lead_form',
  VIEW_FEATURES: 'view_features',
  HIDE_FEATURES: 'hide_features',
  VIEW_FEATURE_HIGHLIGHTS: 'view_feature_highlights',
  VIEW_MORE: 'view_more',
  VIEW_LESS: 'view_less',
  VIEW_PRICE_DETAILS: 'view_price_details',
  VIEW_PRICE_GUIDANCE: 'view_price_guidance',
  VIEW_BUILD_DETAILS: 'view_build_details',
  VIEW_SUBNAV_CONTENT: 'view_subnav_content',
  VIEW_MODAL: 'view_modal',
  DISPLAY_LEAD_FORM: 'display_lead_form',
  DISPLAY_CHAT_LEAD_FORM: 'display_chat_lead_form',
  DISPLAY_CHAT_LEAD_FORM_FAILURE: 'display_chat_lead_form_failure',
  DISPLAY_SALES_LEAD_FORM: 'display_sales_lead_form',
  DISPLAY_LOCATION_PROMPT: 'display_location_prompt',
  EXPAND_LEAD_FORM: 'expand_lead_form',
  MESSAGE_INPUT_CLICK: 'message_input_click',
  FILTER_SEARCH_RESULT: 'filter_search_result',
  FILTER_SEARCH_RESULT_REMOVE: 'remove_filter',
  MULTI_SEARCH: 'multi_search',
  SORT_SEARCH_RESULT: 'sort_search_result',
  ITEMS_PER_PAGE: 'items_per_page',

  OTHER_SITE_CLICKOUT: 'other_site_clickout',
  SEO_INTERNAL_LINK: 'seo_internal_link',

  VIEW_SIGN_UP: 'view_sign_up',
  ACCESS_MY_ACCOUNT: 'access_my_account',
  AUTHENTICATE_SOCIAL: 'authenticate_social',
  AUTHENTICATE_SOCIAL_START: 'authenticate_social_start',
  AUTHENTICATE_SIGN_IN: 'authenticate_email',
  AUTHENTICATE_FIREBASE: 'authenticate_firebase',
  AUTHENTICATE_FIREBASE_ERROR: 'authenticate_firebase_error',
  RECOVER_PASSWORD: 'recover_password',
  SUBMIT_AUTHENTICATE_EMAIL: 'submit_authenticate_email',
  SUBMIT_FORGOT_PASSWORD: 'submit_forgot_password',
  VIEW_SUCCESS_FORGOT: 'view_success_forgot_password',
  PROVIDE_FIRST_NAME: 'provide_first_name',
  PROVIDE_LAST_NAME: 'provide_last_name',
  PROVIDE_EMAIL: 'provide_email',
  PROVIDE_PASSWORD: 'provide_password',
  BEGIN_SIGN_UP: 'begin_sign_up',
  BEGIN_SIGN_IN: 'begin_sign_in',
  SUBMIT_SIGN_UP: 'submit_sign_up',
  SUBMIT_SIGN_UP_EMAIL: 'submit_sign_up_email',
  SUCCESS_SIGN_UP: 'success_sign_up',
  SUCCESS_EMAIL_ONLY_SIGN_UP: 'success_email_only_sign_up',
  SUCCESS_SIGN_IN: 'success_sign_in',
  SUCCESS_AUTHENTICATE_EMAIL: 'success_authenticate_email',
  SUCCESS_ANONYMOUS_SIGN_UP: 'success_anonymous_sign_up',
  SUBSCRIBE_CBP: 'subscribe_cbp',
  SAVE_VEHICLE: 'save_vehicle',
  SAVE_VIN: 'save_vin',
  SAVE_APPRAISAL: 'save_appraisal',
  SHOW_INCENTIVE_AMOUNT: 'show_incentive_amount',
  SHOW_INCENTIVE_DAYS_TO_EXPIRE: 'show_incentive_days_to_expire',
  UNSAVE_VIN: 'unsave_vin',
  SAVE_SEARCH: 'save_search',
  UNSAVE_SEARCH: 'unsave_search',
  SEARCH_BY_TABS: 'search_by_tabs',
  SAVE_BUILD: 'save_build',
  UNSAVE_BUILD: 'unsave_build',
  SIGN_OUT: 'sign_out',
  VIEW_APPRAISAL_DETAILS: 'view_appraisal_details',
  VIEW_APPRAISAL_REPORT: 'view_appraisal_report',
  VIEW_APPRAISAL_INFO: 'view_appraisal_info',
  MIN_APPRAISAL_RANGE_VALUE: 'min_appraisal_range_value',
  MAX_APPRAISAL_RANGE_VALUE: 'max_appraisal_range_value',
  VIEW_CBP_DETAILS: 'view_cbp_details',
  REMOVE_SEARCH_TRIGGER: 'remove_search_trigger',
  REMOVE_SEARCH: 'remove_search',
  VIEW_SEARCH: 'view_search',
  CLOSE_CHECKMARK_X: 'close_coachmark_x',
  CLOSE_CHECKMARK: 'close_coachmark_click',
  CLOSE_CONFIRMATION_X: 'close_confirmation_x',
  CLOSE_CONFIRMATION: 'close_confirmation_click',
  SAVE_MODEL: 'save_model',
  UNSAVE_MODEL: 'unsave_model',
  VIEW_SAVED_MODELS: 'view_saved_models',
  VIEW_SUGGESTED_VIN: 'view_suggested_vin',
  VIEW_SUGGESTED_PRICE_DROP: 'view_suggested_price_drop',
  PRICE_DROP: 'price_drop',
  VIEWED_VIN_PRICE_DROP: 'viewed_vin_price_drop',
  SHOW_NOTIFICATION_COUNT: 'show_notification_count',
  SHOW_SAVE_VIN_COUNT: 'show_saved_vin_count',
  SHOW_VIEWED_VIN_COUNT: 'show_viewed_vin_count',
  SHOW_DAYS_TO_SELL_URGENT: 'show_days_to_sell_urgent',
  SHOW_DAYS_TO_SELL: 'show_days_to_sell',
  PROVIDE_MESSAGE: 'provide_message',

  SUBMIT_UNSUBSCRIBE: 'submit_unsubscribe',
  CANCEL_UNSUBSCRIBE: 'cancel_unsubscribe',
  SUBMIT_RESUBSCRIBE: 'submit_resubscribe',
  RESET_PASSWORD_SUCCESS: 'reset_password_success',
  RESET_PASSWORD_FAILURE: 'reset_password_failure',
  RESET_PASSWORD_CONTINUE: 'reset_password_continue',

  SOCIAL_CLICKOUT: 'social_clickout',
  SOCIAL_CLICKOUT_SIMULATED: 'social_clickout_simulated',

  PARTNER_DEAL_CLICK: 'partner_deal_click',
  SELECT_PARTNER_OFFER: 'select_partner_offer',
  VIEW_OFFER_DETAILS: 'view_offer_details',
  VIEW_OFFER: 'view_offer',
  PARTNER_OFFER_POSITION: 'partner_offer_position',
  REQUEST_REDEMPTION_INFO: 'request_redemption_info',
  OFFER_OF_INTEREST: 'offer_of_interest',
  DISABLE_PARTNER_SOLICITATION: 'disable_partner_solicitation',
  SAVE_OFFER: 'save_offer',
  UPDATE_CURRENT_MILEAGE: 'update_current_mileage',
  UPDATE_MONTHLY_MILEAGE: 'update_monthly_mileage',
  DEALER_SITE_CLICKOUT: 'dealer_site_clickout',
  EDMUNDS_DEALER_SITE_CLICKOUT: 'edmunds_dealer_site_clickout',
  CALL_DEALER: 'call_dealer',
  CALL_DEALER_ROOFTOP_ID: 'call_dealer_rooftop_id',
  CALL_EDMUNDS_SUPPORT: 'call_edmunds_support',
  TEXT_DEALER: 'text_dealer',
  CHAT_DEALER: 'chat_dealer',
  VIEW_DEALER_HOURS: 'view_dealer_hours',
  VIEW_DEALER_HOURS_AND_DIRECTIONS: 'view_dealer_hours_and_directions',
  VIEW_DEALER_REVIEW: 'view_dealer_review',
  VIEW_DEALER_REVIEWS: 'view_dealer_reviews',
  VIEW_DEALER_REVIEW_FULL: 'view_dealer_review_full',
  VIEW_DEALER_DETAILS: 'view_dealer_details',
  VIEW_DEALER_DETAILS_ROOFTOP_ID: 'view_dealer_details_rooftop_id',
  VIEW_DEALER_COMMENTS: 'view_dealer_comments',
  VIEW_DEALER_INVENTORY: 'view_dealer_inventory',
  VIEW_DEALER_CONVO: 'view_dealer_convo',
  VIEW_MORE_INVENTORY: 'view_more_inventory',
  VIEW_MORE_LOCATIONS: 'view_more_locations',
  GO_TO_REVIEW_FORM: 'go_to_review_form',
  GO_TO_DEALER_REVIEW_FORM: 'go_to_dealer_review_form',
  GO_TO_PRICE_CHECKER: 'go_to_price_checker',
  GO_TO_MSRP: 'go_to_msrp_page',
  REPORT_IT: 'report_it',
  UPDATE_REVIEW: 'update_review',
  POST_COMMENT: 'post_comment',
  GET_DIRECTIONS_TO_DEALER: 'get_directions_to_dealer',

  VIEW_ARTICLE: 'view_article',
  READ_ALL_REVIEWS: 'read_all_reviews',

  VIEW_VAPORWARE: 'view_vaporware',
  SHOW_VAPORWARE: 'show_vaporware',
  LOAD_VAPORWARE: 'load_vaporware',

  VIEW_LEASE_DEALS: 'view_lease_deals',
  SELECT_A_VEHICLE: 'select_a_vehicle',
  SELECT_CREDIT_SCORE: 'select_credit_score',
  INPUT_ZIP_CODE: 'input_zip_code',
  INPUT_CREDIT_SCORE: 'input_credit_score',
  SHOW_CALCULATORS: 'show_calculators',
  HIDE_CALCULATORS: 'hide_calculators',
  SHOW_LOAN_PAYMENTS: 'show_loan_payment',
  SHOW_LEASE_PAYMENTS: 'show_lease_payment',
  SHOW_DEALER_PRICE: 'show_dealer_price',
  SHOW_TMV_ESP_MISMATCH_PAYMENT: 'show_tmv_esp_mismatch_payment',
  HIDE_OUT_THE_DOOR_PRICE_DETAILS: 'hide_out_the_door_price_details',
  SHOW_OUT_THE_DOOR_PRICE_DETAILS: 'show_out_the_door_price_details',
  CAR_LOAN_CALCULATOR: 'car_loan_calculator',
  CERTIFIED_CARS: 'certified_cars',
  CAR_LEASE_CALCULATOR: 'car_lease_calculator',
  AFFORDABILITY_CALCULATOR: 'affordability_calculator',
  LINK_TO_CALCULATOR: 'link_to_calculator',
  INPUT_MONTHLY_PAYMENT: 'input_monthly_payment',
  INPUT_VEHICLE_PRICE: 'input_vehicle_price',
  INPUT_PRICE: 'input_price',
  INPUT_PREFERENCE: 'input_preference',
  NAVIGATION_TO: 'navigate_to',
  NAVIGATION_BACK: 'navigate_back',
  NAVIGATION_NEXT: 'navigate_next',
  NAVIGATION_CLOSE: 'navigate_close',
  SUBMIT: 'submit',
  SUBMIT_FOR_CREDIT_SCORE: 'submit_for_credit_score',
  PAYMENT_TYPE_DROPDOWN: 'payment_type_dropdown',
  PAYMENT_TYPE_TOGGLE: 'payment_type_toggle',

  EMAIL_INPUT_SUCCESS: 'email_input_success',
  EMAIL_INPUT_FAIL: 'email_input_fail',
  PHONE_INPUT_SUCCESS: 'phone_input_success',
  PHONE_INPUT_FAIL: 'phone_input_fail',

  ATTEMPT_SUBMIT: 'attempt_submit',
  PROCESSED_SUBMIT: 'processed_submit',

  EDIT_LOAN_PAYMENT: 'edit_loan_payment',
  EDIT_LEASE_PAYMENT: 'edit_lease_payment',
  EDIT_VEHICLE: 'edit_vehicle',
  EDIT_VEHICLE_ERROR_STATE: 'edit_vehicle_error_state',
  EDIT_VEHICLE_PRICE: 'edit_vehicle_price',
  CLOSE_LOAN_CALCULATOR: 'close_loan_calculator',
  CLOSE_LEASE_CALCULATOR: 'close_lease_calculator',
  SELECT_LOAN_PAYMENT_TERM: 'select_loan_payment_term',
  SELECT_LEASE_PAYMENT_TERM: 'select_lease_payment_term',
  SELECT_ANNUAL_MILES: 'select_annual_miles',
  SELECT_VEHICLE: 'select_vehicle',
  SELECT_VEHICLE_CTA_BUTTON: 'select_vehicle_cta_button',
  SELECT_VEHICLE_ERROR_STATE: 'select_vehicle_error_state',
  SELECT_LOAN_VEHICLE: 'loan_select_vehicle',
  SELECT_LEASE_VEHICLE: 'lease_select_vehicle',
  SELECT_AFFORDABILITY_VEHICLE: 'affordability_select_vehicle',
  SELECT_INCENTIVE_VEHICLE: 'incentive_select_vehicle',
  SELECT_MAINTENANCE_VEHICLE: 'maintenance_select_vehicle',
  SELECT_GAS_GUZZLER_VEHICLE: 'gas_guzzler_select_vehicle',
  INPUT_LOAN_DOWN_PAYMENT: 'input_loan_down_payment',
  INPUT_LEASE_DOWN_PAYMENT: 'input_lease_down_payment',
  INPUT_TRADE_IN_VALUE: 'input_trade_in_value',
  INPUT_ANNUAL_PERCENTAGE_RATE: 'input_annual_percentage_rate',
  OPEN_LOAN_CALCULATOR: 'open_loan_calculator',
  OPEN_LEASE_CALCULATOR: 'open_lease_calculator',
  OPEN_LEASE_VS_BUY_CALCULATOR: 'open_lease_vs_buy_calculator',
  OPEN_AFFORDABILITY_CALCULATOR: 'open_affordability_calculator',
  OPEN_INCENTIVE_CALCULATOR: 'open_incentive_calculator',
  OPEN_GAS_GUZZLER_CALCULATOR: 'open_gas_guzzler_calculator',
  VIEW_LEASE_DETAILS: 'view_lease_details',
  VIEW_LOAN_DETAILS: 'view_loan_details',

  OEM_SITE_CLICKOUT: 'oem_site_clickout',

  SAVE_VEHICLE_REVIEW: 'save_vehicle_review',
  REVIEW_ANOTHER_VEHICLE: 'review_another_vehicle',
  CHANGE_VEHICLE: 'change_vehicle',
  VIEW_VEHICLE: 'view_vehicle',
  SEE_ALL_SAVED: 'see_all_saved',
  SEE_ALL_USED: 'see_all_used',
  SEE_ALL_NEW: 'see_all_new',
  SEE_SAVED_DEALS: 'see_saved_deals',
  START_SHOPPING: 'start_shopping',
  OPEN_PHOTOFLIPPER: 'open_photoflipper',

  VIDEO_START: 'video_start',
  VIDEO_STOP: 'video_stop',
  VIDEO_PAUSE: 'video_pause',
  VIDEO_END: 'video_end',
  VIDEO_DISPLAYED: 'video_displayed',

  CLOSE_AD: 'close_ad',

  LEARN_ABOUT_TED: 'learn_about_ted',
  LEARN_PRICING_SCORES: 'learn_about_pricing_scores',

  SHOW_VEHICLE_HISTORY_REPORT: 'show_vehicle_history_report',
  HISTORICAL_TMV_DELTA: 'historical_tmv_delta',
  TMV_PREDICTION: 'tmv_prediction',
  SHOW_PREDICTION: 'show_prediction',
  EXPERIAN_FREE_VHR: 'experian_free_vhr',
  EXPERIAN_PAID_VHR: 'experian_paid_vhr',
  BUYBACK_PROTECTION: 'buyback_protection',
  TEXAS_DMV_CONTACT: 'texas_dmv_contact',

  VIEW_APPRAISAL_FORM: 'view_appraisal_form',
  SUBMIT_LOCATION_STYLE: 'submit_location_style',
  SUBMIT_LOCATION: 'submit_location',
  SUBMIT_CONDITION_QUESTIONS: 'submit_condition_questions',
  CONDITION_QUESTIONS_VALIDATION: 'condition_questions_validation',
  SUBMIT_CONDITION_MILEAGE: 'submit_condition_mileage',
  SUBMIT_CONDITION: 'submit_condition',
  UPDATE_MILEAGE: 'update_mileage',
  SUBMIT_MILEAGE: 'submit_mileage',
  SUBMIT_COLORS_OPTIONS: 'submit_color_options',
  SUBMIT_ZIPCODE: 'submit_zipcode',
  SUBMIT_YEAR: 'submit_year',
  SUBMIT_STYLE: 'submit_style',
  SUBMIT_STYLE_DROPDOWN: 'submit_style_dropdown',
  SUBMIT_TYPE_DROPDOWN: 'submit_type_dropdown',
  SUBMIT_TRIM_DROPDOWN: 'submit_trim_dropdown',
  SUBMIT_COLOR: 'submit_color',
  SUBMIT_EXTERIOR_COLOR: 'submit_exterior_color',
  SUBMIT_INTERIOR_COLOR: 'submit_interior_color',
  SUBMIT_OPTIONS: 'submit_options',
  SUBMIT_LOW_VALUE_OPTIONS: 'submit_low_value_options',
  USER_SELECTED_TRANSMISSION_TYPE: 'user_selected_transmission_type',

  VIEW_APPRAISAL: 'view_appraisal',
  VIEW_DETAILED_REPORT: 'view_detailed_report',
  ADD_EST_PAYMENT: 'add_to_monthly_payment_calc',
  REVIEW_SPECS: 'reviews_specs',
  CLOSE_DRAWER: 'close_drawer',
  CLOSE_PHOTOFLIPPER: 'close_photoflipper',
  CLOSE: 'close',
  CLOSE_MODAL: 'close_modal',
  VIEW_CPO_SECTION: 'view_cpo_section',
  VIEW_CPO_PROGRAM: 'view_cpo_program',
  VIEW_CPO_PAGE: 'view_cpo_page',

  FIND_OUT_WHY: 'find_out_why',
  GET_PREAPPROVED_LOAN: 'get_preapproved_loan',
  APPRAISE_MY_CAR: 'appraise_my_car',
  APPRAISE_YOUR_CAR: 'appraise_your_car',

  SEE_CARS_VALUE: 'see_cars_value',
  RESEARCH_NEW_CARS: 'research_new_cars',
  DISCUSS_CARS: 'discuss_cars',

  READ_MORE: 'read_more',
  SEE_MORE: 'see_more',

  VIEW_MORE_INFO: 'view_more_info',
  VIEW_SAFETY_RATINGS: 'view_safety_ratings',
  VIEW_DEAL_DETAILS: 'view_deal_details',
  VIEW_AUTO_COMPLETE_RESULT: 'view_autocomplete_result',
  VIEW_TOOLTIP: 'view_tooltip',
  EMPTY_AUTO_COMPLETE_RESULT: 'empty_autocomplete_result',
  ABANDON_AUTO_COMPLETE_RESULT: 'abandon_autocomplete_result',
  VIEW_MATCH_DETAILS: 'view_match_details',
  SELECT_AUTOCOMPLETE: 'select_autocomplete',

  VIEW_TRIM_SRP: 'view_trim_srp',
  CLICK_TO_HELP_ME_CHOOSE: 'click_to_help_me_choose',

  NAVIGATE_EXPAND: 'navigate_expand',
  NAVIGATE_COLLAPSE: 'navigate_collapse',

  JUMP_TO_TOP: 'jump_to_top',
  JUMP_TO_SUBCATEGORY_CONTENT: 'jump_to_subcategory_content',

  SYNPARTNER: 'find',
  ICO_SYNPARTNER: 'instantcashoffer_dealer',

  CREATIVE_ID_USED_SUMMARY_CHECKOUT_BASIC: 'used-summary_checkout-basic',
  CREATIVE_ID_USED_SUMMARY_CHECKOUT_MODAL_BASIC: 'used-summary_checkout-modal-basic',
  CREATIVE_ID_VDP_INSTANT_CACHE_OFFER: 'vdp-instant-cash-offer',
  CREATIVE_ID_EDM_ENTRY_ICO_DEFAULT_EDMUNDS: 'edm-entry-ico-default-edmunds-theme',
  CREATIVE_ID_EDM_ENTRY_ICO_REDUCED_HEIGHT_STANDARD_EDMUNDS: 'edm-entry-ico-reduced-height-standard-edmunds',
  SUBACTION_ICO_DEALER: 'ico_dealer',
  CREATIVE_ID_EDM_ENTRY_ICO_REDUCED_HEIGHT_STANDARD_EDMUNDS_THEME:
    'edm-entry-ico-reduced-height-standard-edmunds-theme',
  CREATIVE_ID_ICO_APPRAISAL_ZIP_CODE: 'ico_appraisal_zip_code',
  ICO_LEAD_FORM: 'ico_lead_form',
  ICO_NATIONWIDE_EDMUNDS: 'ico_nationwide_edmunds',
  SUBACTION_ICO_LEAD_FORM_VALIDATION: 'lead_form_validation',
  SUBACTION_ICO_NATIONAL_LANDING_DEALER: 'national_landing_dealer',
  SUBACTION_ICO_DISPLAY_TYPE: 'display_type',
  SUCCESS: 'success',

  VIEW_CBP_LANDING: 'view_cbp_landing',

  ZIP_CODE_ENTRY: 'zip_code_entry',

  YES_FEEDBACK: 'yes_feedback',
  NO_FEEDBACK: 'no_feedback',
  SEND_FEEDBACK: 'send_feedback',
  SEND_TO_PHONE: 'send_to_phone',

  VERIFY_PURCHASE_DEALER: 'verify_purchase_dealer',

  VIEW_SRP: 'view_srp',
  VIEW_VDP: 'view_vdp',

  VIEW_ESP: 'view_esp',
  VIEW_ESTIMATE: 'view_estimate',
  VIEW_EV_TAX_CREDIT: 'view_ev_tax_credit',

  SELL_YOUR_CAR: 'sell_your_car',
  SELL_MY_CAR: 'sell_my_car',

  BUILD_AND_PRICE: 'build_and_price',
  CUSTOMIZE_PAYMENTS: 'customize_payments',
  GET_PREQUALIFIED: 'get_prequalified',

  SELECT_DOWN_PAYMENT: 'select_downpayment',
  SELECT_TRADE_IN: 'select_tradein',
  SELECT_LEASE_TERM: 'select_lease_term',
  SELECT_LOAN_TERM: 'select_loan_term',
  SELECT_MILEAGE: 'select_mileage',
  SELECT_INCENTIVES: 'select_incentives',
  REMOVE_INCENTIVES: 'remove_incentives',
  SHARE_DEAL: 'share_deal',
  SHARE_CALCULATOR: 'share_calculator',
  GENERATE_LINK: 'generate_link',
  COPY_LINK: 'copy_link',
  COPY_SUMMARY: 'copy_summary',
  CALCULATE_PAYMENT: 'calculate_payment',
  CALCULATE_PAYMENTS: 'calculate_payments',
  PREPOPULATE_TRADE_IN: 'prepopulate_trade_in_value',
  CHOOSE_PURCHASE_TYPE: 'choose_purchase_type',
  SEARCH_PERKS: 'search_perks',
  SEARCH_DEALS: 'search_deals',
  SEARCH_INVENTORY: 'search_inventory',
  FIND_MY_PERFECT_CAR: 'find_my_perfect_car',
  SEE_SOMETHING_WRONG: 'see_something_wrong',
  PAYMENT_FEEDBACK: 'payment_feedback',

  GET_STARTED: 'get_started',
  GET_OFFERS: 'get_offers',

  VIEW_PREVIOUS_PAGE: 'view_previous_page',

  EDIT_MILEAGE: 'edit_mileage',
  EDIT_COLOR: 'edit_color',
  EDIT_OPTIONS: 'edit_options',
  OFFER_SELECTED: 'offer_selected',
  OFFER_STRENGTH: 'offer_strength',
  KMX_OFFER_STRENGTH: 'kmx_offer_strength',
  ESTIMATE_SELECTED: 'estimate_selected',
  SUBMIT_VIN: 'submit_vin',
  EDIT_VIN: 'edit_vin',
  SUBMIT_LICENSE_PLATE: 'submit_license_plate',
  SUBMIT_INTRO: 'submit_intro',
  SUBMIT_LEAD: 'submit_lead',
  SQUISHVIN_ENDPOINT: 'squishvin_endpoint',
  UNIVERSAL_VIN_ENDPOINT: 'universal_vin_endpoint',
  SHOW_CLICK_TO_CALL_EMAIL: 'show_click_to_call_email',
  REPORT_TAB: 'report_tab',
  VIN_FORMAT_INVALID: 'vin_format_invalid',
  VIN: 'vin',
  VIN_AUTOFILL: 'vin_autofill',
  VIN_PREPOPULATED: 'vin_prepopulated',
  VIN_FOUND: 'vin_found',
  VIN_SHOWN: 'vin_shown',
  VINS_FOUND: 'vins_found',
  VIN_NOT_FOUND: 'vin_not_found',
  VIN_NOT_SHOWN: 'vin_not_shown',
  LOOKUP_ERROR: 'lookup_error',

  DOWNLOAD_APP: 'download_app',

  VISIT_TED: 'visit_ted',
  VISIT_SRP: 'visit_srp',

  ADD_VEHICLE_COMPARE: 'add_vehicle_compare',
  SUGGESTED_COMPARE_SET: 'suggested_compare_set',
  COMPLETE_DELIGHTED_SURVEY: 'complete_delighted_survey',
  COMPLETE_USABILITY_SURVEY: 'completed_usability_survey',
  COMPLETE_TED_READINESS_SURVEY: 'complete_ted_readiness_survey',
  COMPLETE_OFFER_SURVEY: 'complete_offer_survey',

  GET_INSTANT_OFFER: 'get_instant_offer',
  MILEAGE_INPUT: 'mileage_input',
  EXPOSE_COMMENTS: 'expose_coments',
  COMMENT_INPUT_SUCCESS: 'comment_input_success',
  SUBJECT_SELECT: 'subject_select',

  RATING_CATEGORY: 'rating_category',
  SHOW_TEASER: 'show_teaser_yes_no',
  DISPLAY_4TH_PRICING_CARD: 'display_4th_pricing_card',
  PRICING_CARDS_DISPLAYED: 'pricing_cards_displayed',
  PRICING_CARDS_MISSING_PHOTOS: 'pricing_cards_missing_photos',

  DLI_CLICK: 'dli_click',

  SELECT_PRICE_CHECKER_MODEL: 'select_price_checker_model',
  CHANGE_PRICE_CHECKER_MODEL: 'change_price_checker_model',

  SUBACTION_FAQ_CONTENT: 'faq_content',
  SUBACTION_SHARE_CHART: 'share_chart',
  SUBACTION_CHART_CLICK: 'chart_click',

  PLAY_PAUSE: 'play_pause',

  MINOR_BREAKDOWN_ERROR: 'minor_breakdown_error',

  /* All other appraisal's page subaction constants will be refactored in the story EVA-1497 */
  SUBACTION_VIN_ENTRY: 'vin_entry',
  SUBACTION_SQUISHVIN_DECODE: 'squishvin_decode',
  SUBACTION_CORRECT_SQUISHVIN: 'correct_squishvin',
  SUBACTION_CORRECT_SQUISHVIN_SECONDARY: 'correct_squishvin2',
  SUBACTION_VIN_VALIDATION: 'vin_validation',
  FIND_VIN: 'find_vin',
  FIND_STYLE: 'find_style',
  FIND_OPTIONS: 'find_options',
  SUBACTION_GRAPHQL_VEHICLE_STYLE_REQUEST: 'graphQL_vehicle_style_request',
  SUBACTION_TMV_APPRAISAL: 'tmv_appraisal',
  SUBACTION_EPO_OFFER: 'epo_offer',
  SUBACTION_EPO_OFFER_VIN: 'epo_offer_vin',
  SUBACTION_EPO_NO_OFFER_VIN: 'epo_no_offer_vin',
  SUBACTION_SOURCE_ID: 'source_id',
  SUBACTION_EPO_MMY_ZIP_ELIGIBILITY_CHECK: 'epo_mmy_zip_eligibility_check',
  SUBACTION_EPO_VIN_ELIGIBILITY_CHECK: 'epo_vin_eligibility_check',
  SUBACTION_EPO_ESTIMATED_OFFER: 'epo_estimated_offer',
  SUBACTION_EPO_ESTIMATED_OFFER_ELIGIBILITY_CHECK: 'epo_estimated_offer_eligibility_check',
  SUBACTION_EPO_ESTIMATED_OFFER_TEASER: 'epo_estimated_offer_teaser',
  SUBACTION_EPO_ESTIMATED_OFFER_VIN: 'epo_estimated_offer_vin',
  SUBACTION_EPO_SKU_SPEC_LOOKUP: 'epo_sku_specifications_lookup',
  SUBACTION_EPO_RECEIVE_APPOINTMENT_SLOTS: 'epo_receive_appointment_slots',
  SUBACTION_EPO_GET_APPOINTMENT_SLOTS: 'epo_get_appointment_slots',
  SUBACTION_WEBSOCKET_INITIAL_PUSH_CONTAINS_UPDATES: 'websocket_initial_push_contains_updates',
  SUBACTION_WEBSOCKET_SUBSEQUENT_PUSH_WITH_UPDATES: 'websocket_subsequent_push_with_updates',
  SUBACTION_WEBSOCKET_PUSH_OFFERS_FINALIZED: 'websocket_push_offers_finalized',
  SUBACTION_WEBSOCKET_PUSH_REDEMPTION_INFO_FINALIZED: 'websocket_push_redemption_info_finalized',
  SUBACTION_WEBSOCKET_FIRST_CONNECTION: 'websocket_first_connection',
  SUBACTION_WEBSOCKET_CONNECTION: 'websocket_connection',
  SUBACTION_WEBSOCKET_RECONNECT: 'websocket_reconnect',
  SUBACTION_WEBSOCKET_CONNECTION_TIMEOUT: 'websocket_connection_timeout',
  SUBACTION_WEBSOCKET_CONNECTION_ERROR: 'websocket_connection_error',
  SUBACTION_OFFER_ESTIMATE: 'offer_estimate',
  SUBACTION_OFFER_ESTIMATE_PATCH: 'offer_estimate_patch',
  SUBACTION_ESTIMATE_DECLINE_REASON: 'estimate_decline_reason_value',
  SUBACTION_CUSTOMER_CONSENT_KMX_APPT: 'customer_consent_for_carmax_appt',
  SUBACTION_GTM_FB_AUDIENCE_PROS: 'gtm_fb_audience_pros',
  SUBACTION_DEALER_SELECT_DISTANCE: 'dealer_select_distance',
  SUBACTION_DEALER_SELECT: 'dealer_select',
  SUBACTION_DEALER_SELECTION: 'dealer_selection',
  SUBACTION_DEFAULT_DEALER_DISTANCE: 'default_dealer_distance',
  SUBACTION_VIN_INVENTORY_CHECK: 'vin_inventory_check',
  SUBACTION_APPRAISAL_PREFILL: 'appraisal_prefill',
  SUBACTION_SUBMODEL_CONTAINS_CAB: 'submodel_contains_cab',
  SHOW_TRADE_IN_LIMIT_ERROR: 'show_tradein_value_limit_error',
  SHOW_DOWN_PAYMENT_LIMIT_ERROR: 'show_downpayment_value_limit_error',
  SUBACTION_SQUISHVIN_VALIDATION: 'squishvin_validation',
  SUBACTION_VIN_ERROR: 'vin_error',
  SUBACTION_VIN_ENTRY_INELIGIBLE_MMY_ZIP: 'vin_entry_ineligible_mmy_zip',
  SUBACTION_VIEW_PARTNER_OFFER: 'view_partner_offer',
  SUBACTION_OFFER_CODE: 'offer_code',
  SUBACTION_QUOTE_ID_RETURNED: 'quote_id_returned',
  SUBACTION_TEXT_OFFER: 'text_offer',
  SUBACTION_OPEN_CHECKOUT: 'open_checkout',
  ZERO_VALUATION: 'ZERO_VALUATION',
  NO_OFFER_CODE: 'no_code',
  INSIDER_APPRAISAL_CARD: 'insider_appraisal_card',
  SUBACTION_CONTINUE_APPRAISAL: 'continue_appraisal',
  SUBACTION_VIEW_APPRAISAL: 'view_offer',
  SUBACTION_RENEW_OFFER: 'renew_offer',
  SUBACTION_STEP_SELECT: 'step_select',
  SUBACTION_VIN_STEP_BYPASS: 'vin_step_bypass',
  SUBACTION_DECLINE_REASON: 'decline_reason',
  SUBACTION_DECLINE_REASON_VALUE: 'decline_reason_value',
  SUBACTION_EPO_TRANSLATION_OPTIONS: 'epo_translation_options',
  SUBACTION_EPO_TRANSLATION_STANDARD_OPTION_COUNT: 'epo_translation_standard_option_count',
  SUBACTION_EPO_TRANSLATION_AVAILABLE_OPTION_COUNT: 'epo_translation_available_option_count',
  SUBACTION_EPO_TRANSLATION_SKU: 'epo_translation_sku',
  SUBACTION_STYLE_ENGINE_OVERRIDE: 'style_engine_override',
  SUBACTION_STYLE_TRANSMISSION_OVERRIDE: 'style_transmission_override',
  SUBACTION_UNLOCK_APPRAISAL: 'unlock_appraisal',
  SUBACTION_APPRAISAL_RENEWAL_ERROR: 'appraisal_renewal_error',
  SUBACTION_START_NEW_APPRAISAL: 'start_new_appraisal',
  SUBACTION_REFRESH_OFFER_PARTNER: 'refresh_offer_partner',
  SUBACTION_APPRAISAL_LAST_QUOTE_ID_RETURNED: 'last_quote_id_returned',
  SUBACTION_TRACK_CAR_VALUE: 'track_car_value',
  SUBACTION_APPRAISAL_CHECKOUT_DROPOFF: 'appraisal_checkout_dropoff',
  SUBACTION_APPRAISAL_CHECKOUT_SCHEDULE_APPT: 'appraisal_checkout_schedule_appt',
  SUBACTION_APPRAISAL_CHECKOUT_PICKUP: 'appraisal_checkout_pickup',
  SUBACTION_APPRAISAL_CHECKOUT_CLICKOUT: 'appraisal_checkout_clickout',
  SUBACTION_APPRAISAL_CHECKOUT_ELIGIBILITY_CHECK: 'appraisal_checkout_eligibility_check',
  SUBACTION_OPTION_SELECT: 'option_select',
  SUBACTION_TOGGLE_SELECT: 'toggle_select',
  SUBACTION_SUBMIT_INQUIRY: 'submit_inquiry',
  SUBACTION_SECTION_HEADER_MODULE_DISPLAYED: 'section_header_module_displayed',
  SUBACTION_VIDEO_BACKGROUND_MODULE_DISPLAYED: 'video_bkg_module_displayed',
  SIDE_BY_SIDE_MODULE_DISPLAYED: 'side-image-module-displayed',
  SUBACTION_RICH_TEXT_MODULE_DISPLAYED: 'rich_text_module_displayed',
  SUBACTION_STATISTICS_MODULE_DISPLAYED: 'statistics_module_displayed',
  SUBACTION_FEATURES_GRID_MODULE_DISPLAYED: 'features_grid_module_displayed',
  PARTNERSHIPS_MODULE_DISPLAYED: 'partnerships_module_displayed',
  VERSUS_OTHERS_DISPLAYED: 'versus_others_displayed',
  SUBACTION_TESTIMONIALS_DISPLAYED: 'testimonials_displayed',
  SUBACTION_VIEW_TESTIMONIAL: 'view_testimonial',
  SUBACTION_EPO_PROPENSITY_SCORE_BREAKDOWN: 'epo_propensity_score_breakdown',
  SUBACTION_EPO_PROPENSITY_TOTAL_SCORE: 'epo_propensity_total_score',
  SUBACTION_EPO_PROPENSITY_VIN_SCORE: 'epo_propensity_vin_score',
  SUBACTION_STYLEID_VINDECODE_MATCH: 'styleId_vindecode_match',

  SUBACTION_SRP_ERROR: 'srp_error_cta',
  SUBACTION_SRP_RETRY_SEARCH_RESULTS: 'retry_search_results',
  SUBACTION_ATOM_FILE_NAME: 'atom_file_name',

  MMY_MISMATCH: 'mmy_mismatch',
  MMY_ONE_FOUND: 'mmy_found_one',
  MMY_MANY_FOUND: 'mmy_found_many',
  MMY_NOT_FOUND: 'mmy_not_found',
  MMY_MATCH: 'mmy_match',
  NO_RESULTS: 'no_results',

  SHOW_CPO_PANEL: 'show_cpo_panel',
  SHOW_CPO_LABEL: 'show_cpo_label',

  CLOSE_POPUP: 'close_popup',
  VIEW_CONDITION_QUESTIONS: 'view_condition_questions',

  LICENSE_PLATE: 'license_plate',
  LICENSE_PLATE_STATE: 'license_plate_state',
  LICENSE_PLATE_LOOKUP: 'license_plate_lookup',
  LICENSE_PLATE_LOOKUP_SERVICE: 'license_plate_lookup_service',
  NEXT_BUTTON: 'next_button',

  SLIDER_MOVE: 'slider_move',

  SHOW_SILHOUETTE_MISSING: 'show-silhouette-missing',
  SHOW_SILHOUETTE_ERROR: 'show-silhouette-error',
  DISPLAY_THANK_YOU: 'display_thank_you',

  SMS_CLICKOUT: 'sms_clickout',

  LAUNCH_WUFOO_SURVEY: 'launch_wufoo_survey',

  SHOW_CARCODE_EMBEDDED: 'show_carcode_embedded',

  CAR_REVIEWS: 'car_reviews',

  SUBACTION_PREFERS_COLOR_SCHEME: 'prefersColorScheme',
  SUBACTION_PREFERS_COLOR_SCHEME_SUPPORTED: 'prefersColorSchemeSupported',

  PRICE_COMPARISON: 'price_comparison',
  PRICE_COMPARISON_TOOLTIP: 'price_comparison_tooltip',
  VIEW_PRICE_HISTORY: 'view_price_history',

  OFFER_BUTTON: 'offer_button',

  SEE_RECALL_INFO: 'see_recall_info',

  SCHEDULE_APPOINTMENT: 'schedule_appointment',
  SUBMIT_APPOINTMENT: 'submit_appointment',
  APPOINTMENT_DATE: 'appointment_date',
  APPOINTMENT_TIME: 'appointment_time',
  APPOINTMENT_VIN: 'appointment_vin',

  SUBMIT_REVIEW: 'submit_review',
  SUBMIT_COMMENT: 'submit_comment',
  FALLBACK_NO_360: 'fallback_no_360',
  VIEW_360: 'view_360',

  VENOM_REDIRECT: 'venom_redirect',

  COMPLETED_ACCESS: 'completed_access',

  BUY_ONLINE_CLICKOUT: 'buy_online_clickout',
  REQUEST_INFORMATION: 'request_information',

  VISIT_BUY_ONLINE_CHANNEL: 'visit_buy_online_channel',
  LEAVE_BUY_ONLINE_CHANNEL: 'leave_buy_online_channel',
  BUY_ONLINE_SELECT: 'buying-experience_select',
  BUY_ONLINE_UNSELECT: 'buying-experience_unselect',
  SUBMIT_BUY_ONLINE_TYPE: 'submit_buy_online_type',
  SUBMIT_BUY_IN_STORE_TYPE: 'submit_buy_in-store_type',
  VIEW_LOAN_PRICING: 'view_loan_pricing',
  VIEW_LEASE_PRICING: 'view_lease_pricing',
  VIEW_PURCHASE_PRICING: 'view_purchase_pricing',

  // Financing page subactions
  SUBACTION_DOWN_PAYMENT: 'edit_down-payment',
  SUBACTION_TRADE_IN: 'edit_trade-in',
  SUBACTION_AMOUNT_OWNED: 'edit_amount-owed',
  SUBACTION_SELECT_TERM: 'select_term',
  SUBACTION_OPEN_FINANCING_APP: 'open_financing_app',
  SUBACTION_SELECT_APP_TYPE: 'select_application_type',
  SUBACTION_VIEW_FINANCING_APP: 'view_financing_app',
  SUBACTION_RATE_LOOKUP: 'rate-lookup',
  SUBACTION_RATE_LOOKUP_FAILURE: 'rate_lookup-failure',
  SUBACTION_VIEW_ANOTHER_SOURSE: 'another_source_of_income',
  SUBACTION_DISCLOSURE_CHECKBOX: 'consent_to_data_processing',
  SUBACTION_EDIT_FORM: 'edit_form_fields',
  SUBACTION_CHANGE_FINANCING_FORM: 'change_financing_form',
  SUBACTION_CONTINUE_TO_NEXT_SCREEN: 'continue_to_next_screen',
  SUBACTION_CONFIRM_ENTRY: 'confirm_entry',
  SUBACTION_EDIT_ENTRY: 'edit_entry',
  SUBACTION_VIEW_PREQUAL_LANDING: 'view_prequalification_landing',
  DISPLAY_EMAIL_INPUT: 'display_email_input',
  PREQUALIFY: 'prequalify',
  SUBACTION_START_PERSONAL_LOOKUP: 'start_personal_lookup',
  API_SYSTEM_ERROR: 'api_system_error',
  PREQUAL_NEWAPP_APPROVAL: 'prequal_newapp_approval',
  INTEREST_CALC_COACHING: 'interest_calc_coaching',
  EDIT_AMT_FINANCED: 'edit_amt_financed',
  EDIT_LOAN_TERM: 'edit_loan_term',
  EDIT_APR: 'edit_apr',

  // Digital checkout
  DEALER_CATEGORY: 'dealer_category',
  ENROLL_API_RESPONSE: 'enrollment_api_response',
  SUBMIT_ENROLLMENT: 'submit_enrollment',
  SUBMIT_REQUEST: 'submit_request',
  MANAGE_FORM_TOGGLE: 'manage_form_toggle',
  SUBMIT_SURVEY: 'submit_survey',

  // Multi-Offer
  MODS_ID: 'mods_id',
  MULTI_OFFER_VIN: 'multi_offer_vin',
  OFFER_DETAILS_VIN: 'offer_details_vin',
  MODS_RECORD_CREATION_ERROR: 'mods_record_creation_error',
  TOTAL_OFFER_COUNT: 'total_offer_count',
  MODS_RECORD_RETURNED: 'mods_record_returned',
  ACCEPTED_OFFER_COUNT: 'accepted_offer_count',
  DECLINED_OFFER_COUNT: 'declined_offer_count',
  OFFER_DISPLAY_COUNT: 'offer_display_count',
  OFFER_BRAND: 'offer_brand',
  EXPIRED_OFFER_BRAND: 'expired_offer_brand',
  CARWISER_SOLICITED_RESPONSE_STATUS_CODE: 'carwiser_solicited_response_status_code',
  ACTIVE_OFFER_DISPLAY_COUNT: 'active_offer_display_count',
  EXPIRED_OFFER_DISPLAY_COUNT: 'expired_offer_display_count',
  EMO_PREQUALIFY_RESULT: 'emo_prequalify_result',
  EMO_PREQUALIFY_INELIGIBLE_REASON: 'emo_prequalify_ineligible_reason',
  EMO_MILEAGE_ELIGIBILITY: 'emo_mileage_eligibility',
  EMO_PARTNER_TO_SOLICIT: 'emo_partner_to_solicit',
  MULTI_OFFER_SEARCH: 'multi_offer_search',
  TMV_APPRAISAL_ERROR: 'tmv_appraisal_error',
  MULTI_OFFER_CONDITION_QUESTIONS: 'multi_offer_condition_questions',
  PARTNERS_TO_SOLICIT: 'partners_to_solicit',
  SOLICIT_MORE_OFFERS_VIN: 'solicit_more_offers_vin',
  MODS_PREPOPULATED: 'mods_prepopulated',
  MAX_OFFER_VALUE: 'max_offer_value',
  MODS_AB_TEST_BUCKETING: 'mods_ab_test_bucketing',
  MULTI_OFFER: 'multi_offer',
  OFFERS_PENDING: 'offers_pending',
  PICSY_ELIGIBLE: 'picsy_eligible',
  ARO_RECENCY_RETURNED: 'aro_recency_returned',
  HISTORICAL_OFFER_VALUE: 'historical_offer_value',
  REFRESHED_OFFER: 'refreshed_offer',
  REFRESHED_OFFER_VALUE: 'refreshed_offer_value',
  REFRESHED_OFFER_VALUE_PERCENTAGE_DELTA: 'refreshed_offer_value_percentage_delta',
  REFRESHED_OFFER_VALUE_DOLLAR_DELTA: 'refreshed_offer_value_dollar_delta',
  REFRESHED_OFFER_VALUE_TREND_DIRECTION: 'refreshed_offer_value_trend_direction',
  FOCUS_FIRST_SEARCH_FIELD: 'focus_first_search_field',
  SUBMIT_YMM: 'submit_ymm',
  RENDER_FIRST_RESULTS: 'render_first_results',

  USER_SAT_SURVEY_ANSWER: 'user-sat-survey_answer',

  ENGAGE_SURVEY: 'engage_survey',

  START_FINANCING_APP: 'start_financing_app',
  SUBACTION_SHOW_DELIVERY_COST: 'show_delivery_cost',
  SUBACTION_SHOW_EV_RANGE_MAP: 'show_ev_range_map',

  AVAILABLE_ON_OTHER_STYLES: 'available_on_other_styles',
  SELECT_SURVEY: 'select_survey',
  // Start Wizard
  DISPLAY_INCENTIVE_DETAILS: 'display_incentive_details',
  DISPLAY_INCENTIVE_PROGRAM_DETAILS: 'display_incentive_program_details',
  VIEW_FILTERS: 'view_filters',
  OPEN_INCENTIVES_MATCHER: 'open_incentives_matcher',
  MATCHED_INCENTIVES_COUNT: 'matched_incentives_count',
  MATCHED_INCENTIVES_SUM: 'matched_incentives_sum',
  DISPLAY_INTRO: 'display_intro',
  DISPLAY_FEDERAL_TAX_CREDIT_INTRO: 'display_federal_tax_credit_intro',
  DISPLAY_FEDERAL_TAX_CREDIT_RESULTS: 'display_federal_tax_credit_results',
  DISPLAY_LOCAL_REBATES_INTRO: 'display_local_rebates_intro',
  DISPLAY_LOCAL_REBATES_RESULTS: 'display_local_rebates_results',
  START_INCENTIVES_MATCHER: 'start_incentives_matcher',
  TRANSACTION_TYPE: 'transaction_type',
  TAX_YEAR: 'tax_year',
  INCOME_CURRENT_YEAR: 'current_year_income',
  INCOME_PREVIOUS_YEAR: 'previous_year_income',
  TAX_STATUS_CURRENT_YEAR: 'current_year_tax_status',
  TAX_STATUS_PREVIOUS_YEAR: 'previous_year_tax_status',
  LEVEL2_CHARGER: 'level2_charger',
  ZIP_CODE: 'zip_code',
  CHARGER_INSTALLATION_GRANTOR: 'charger_installation_grantor',
  DISPLAY_INELIGIBLE_INCENTIVES_DETAILS: 'display_ineligible_incentive_details',
  CHECK_AVAILABILITY: 'check_availability',
  SEARCH: 'search',
  INCENTIVE_OFFER: 'incentive_offer',
  VIEW_INCENTIVES: 'view_incentives',
  SEND_MARKETO_LEAD_CONFIRM: 'send_marketo_lead_confirm',
  ASSISTANCE_PARTICIPANT: 'assistance_participant',
  HOUSEHOLD_SIZE: 'household_size',

  // End Wizard
  CHECKOUT_DEEPLINK_STARTED: 'checkout_deeplink_started', // TODO: should be removed when clean up TED-6159-Caramel-Checkout-V1
  CHECKOUT_DEEPLINK_COMPLETED: 'checkout_deeplink_completed', // TODO: should be removed when clean up TED-6159-Caramel-Checkout-V1
  OFFER_ESTIMATE_MIN_VALUE: 'offer_estimate_min_value',
  OFFER_ESTIMATE_MAX_VALUE: 'offer_estimate_max_value',
  SEE_OTHER_YEARS: 'see_other_years',

  // TODO: review during clean up shop-3017-uveye-vaporware
  UVEYE_PROMOTION: 'uveye-promotion',
  UVEYE_CLICKOUT: 'uveye_clickout',
  INPUT_EMAIL_SUCCESS: 'input_email_success',
  SUBMIT_UVEYE_SURVEY: 'submit_uveye_survey',

  MAKE_SELECT_OPEN: 'make_select_open',
};

export const PAGE_CONTENT_UPDATE = 'pageContentUpdate';
