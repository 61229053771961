import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const icoSellCarRoutes = [
  {
    path: `/sell-car/instant-cash-offer`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "ico_nationwide_landing_page" */ '../pages/nationwide-ico-landing')
    ),
    chunkName: 'ico_nationwide_landing_page',
  },
];
