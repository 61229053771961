export const COMPARATOR_INCENTIVES_AD = 'comparator-incentives-ad';
export const INCENTIVES_AD = 'incentives-ad';
export const BUILD_PRICE_AD = 'build-and-price-ad';
export const BUILD_PRICE_BUTTON_AT_A_GLANCE_AD = 'build-and-price-button-at-a-glance-ad';
export const BUILD_PRICE_COMPACT_AD = 'build-and-price-compact-ad';
export const PRICING_AD = 'pricing-ad';
export const PRICING_HORIZONTAL_AD = 'pricing-horizontal-ad';
export const SRP_AD = 'srp-ad';
export const USURP_NATIVE_AD = 'usurp-ad';
export const FILMSTRIP_AD = 'filmstrip-ad';
export const SEGMENT_LISTING_AD = 'segment-listing-ad';
export const SRP_PHOTOFLIPPER_AD = 'srp-photoflipper-ad';
export const INSIGHT_AD = 'insight-ad';
export const INSIGHT_HORIZONTAL_AD = 'insight-horizontal-ad';

export const SITE_SERVED_ADS = [
  COMPARATOR_INCENTIVES_AD,
  INCENTIVES_AD,
  BUILD_PRICE_AD,
  BUILD_PRICE_BUTTON_AT_A_GLANCE_AD,
  BUILD_PRICE_COMPACT_AD,
  PRICING_AD,
  PRICING_HORIZONTAL_AD,
  SRP_AD,
  USURP_NATIVE_AD,
  FILMSTRIP_AD,
  SEGMENT_LISTING_AD,
  SRP_PHOTOFLIPPER_AD,
  INSIGHT_AD,
  INSIGHT_HORIZONTAL_AD,
];
