import { get } from 'lodash';

import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { getModelState } from 'client/data/luckdragon/model';
import { getTypeArticleContentPath } from 'site-modules/editorial/utils/content-path';
import { CmsModel } from 'client/data/models/cms';
import { isBestContent } from 'site-modules/editorial/utils/best';
import { MAKE_ARTICLES } from 'site-modules/shared/constants/editorial/make-articles';
import { parseContent } from 'client/data/cms/content';
import { create404Error } from 'site-modules/shared/utils/errors';

export const TYPES = [
  'convertible',
  'coupe',
  'crossover',
  'diesel',
  'hatchback',
  'hybrid',
  'electric-car',
  'sports-car',
  'minivan',
  'sedan',
  'suv',
  'truck',
  'wagon',
  'luxury',
];

export const ELECTRIC_SUBTYPES = [
  'electric-sedans',
  'electric-trucks',
  'electric-muscle-cars',
  '7-seat-electric-suvs',
  'electric-sports-cars',
  'electric-convertibles',
];

export const NO_EXTENSION_ARTICLES = {
  'electric-car': [
    'ev-buying-guide',
    'best-electric-cars',
    'cheapest-electric-cars',
    'tesla-model-3-vs-tesla-model-y-vs-ford-mustang-mach-e',
    'best-electric-cars-2018',
  ],
};
export const ONE_LEVEL_TYPES = ['2020-ford-bronco', 'ev'];

/**
 * route for each vehicle type
 * @type {Array}
 */
export const typeArticlesRoutes = [
  {
    /**
     * Electric-car articles landing.
     * Note: electric-car can be replaced with :type in future
     */
    path: '/electric-car/articles',
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "type-articles-landing" */ '../pages/type/type-articles-landing/type-articles-landing-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'type-articles-landing',
  },
  {
    /**
     * Route for single type article page, e.g.
     * https://www.edmunds.com/suv/articles/best-small-suvs/
     */
    path: [`/:type(${TYPES.join('|')})/articles/:articleId.:ext`, `/:type(${TYPES.join('|')})/articles/:articleId`],
    exact: true,
    preload: ({ type, articleId, ext }, store) => {
      const preloader = new ModelPreloader(store);
      preloader.resolve(`content["${getTypeArticleContentPath(type, articleId)}"]`, CmsModel);

      const isNoExtensionArticle = get(NO_EXTENSION_ARTICLES, type, []).includes(articleId);
      // if there are no_extension_articles for current type then check it
      // all other types goes without extension by default
      const pageExists = NO_EXTENSION_ARTICLES[type]
        ? (isNoExtensionArticle && !ext) || (!isNoExtensionArticle && ext)
        : !ext;
      if (!pageExists) {
        throw create404Error('Page not found');
      }

      return preloader.load();
    },
    page: getLoadablePage(({ type, articleId }, store) => {
      const modelState = getModelState(store.getState());
      const article = parseContent(
        modelState.get(`content["${getTypeArticleContentPath(type, articleId)}"]`, CmsModel)
      );
      const template = article.metadata('template').value();

      const isBestContentId = isBestContent(get(article, 'id'));
      if (isBestContentId) {
        return import(/* webpackChunkName: "type-best-article" */ '../pages/type/type-best/type-best-definition').then(
          page => page.default({ type, articleId, chunkName: 'type-best-article' })
        );
      } else if (template === 'type-buying-guide') {
        return import(/* webpackChunkName: "type-buying-guide-article" */ 'site-modules/editorial/pages/type-buying-guide/type-buying-guide-definition').then(
          page => page.default({ type, articleId, chunkName: 'type-buying-guide-article' })
        );
      } else if (article.metadata('oneColumnTemplate').boolean()) {
        return import(/* webpackChunkName: "type-one-column-article" */ '../pages/type/one-column/one-column-definition').then(
          page => page.default({ type, articleId, chunkName: 'type-one-column-article' })
        );
      }
      return import(/* webpackChunkName: "type-two-column-article" */ '../pages/type/two-column/two-column-definition').then(
        page => page.default({ type, articleId, chunkName: 'type-two-column-article' })
      );
    }),
  },
  {
    /**
     * Route for specific electric car type article page:
     * https://www.edmunds.com/electric-xxx/
     * This page contains content that was previously at /electric-car/articles/electric-xxx.html
     */
    path: `/:articleId(${ELECTRIC_SUBTYPES.join('|')})`,
    exact: true,
    page: getLoadablePage(({ articleId }) => {
      const hardCodedType = 'electric-car';

      return import(/* webpackChunkName: "type-one-column-article" */ '../pages/type/one-column/one-column-definition').then(
        page => page.default({ type: hardCodedType, articleId, chunkName: 'type-one-column-article' })
      );
    }),
  },

  {
    /**
     * Route for single vehicle/type article page, e.g.
     * https://www.edmunds.com/2020-ford-bronco/
     */
    path: `/:type(${ONE_LEVEL_TYPES.join('|')})`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "type-two-column-article" */ '../pages/type/two-column/two-column-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'type-two-column-article',
  },
  {
    /**
     * Route for a make article page, e.g.
     * https://www.edmunds.com/kia/
     */
    path: `/:type(${MAKE_ARTICLES.join('|')})`,
    exact: true,
    page: getLoadablePage(({ type }) =>
      import(/* webpackChunkName: "type-one-column-article" */ '../pages/type/one-column/one-column-definition').then(
        page =>
          page.default({
            type,
            chunkName: 'type-one-column-article',
          })
      )
    ),
  },
];
