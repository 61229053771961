import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

/**
 * route for tmv page
 * @type {Array}
 */
export const tmvRoute = {
  path: '/tmv.html',
  exact: true,
  page: getLoadablePage(() => import(/* webpackChunkName: "tmv" */ '../pages/tmv-definition')),
  chunkName: 'tmv',
};
