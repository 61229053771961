import { isString } from 'lodash';
import {
  CAR_NEWS_CATEGORY_NAME,
  TEST_CAR_NEWS_CATEGORY_NAME,
} from 'site-modules/shared/constants/editorial/research-article';

export function isCarNews(category) {
  return (
    isString(category) && [CAR_NEWS_CATEGORY_NAME, TEST_CAR_NEWS_CATEGORY_NAME].includes(category.replace(/_/g, '-'))
  );
}
