import { carReviewsRoutes } from './car-reviews';
import { topRatedRoutes } from './top-rated';
import { researchArticlesRoutes } from './research-article';
import { typeArticlesRoutes } from './type-article';
import { typeBeforeBuyRoutes } from './type-before-buy';
import { tmvRoute } from './tmv';
import { tcoRoute } from './tco';
import { aboutRoutes } from './about';
import { sellCarRoutes } from './sell-car';
import { carNewsRoutes } from './car-news';
import { myAppraiseTradeInRoutes } from './myappraise-tradein';

export const routes = Array.prototype.concat(
  topRatedRoutes,
  carNewsRoutes,
  sellCarRoutes,
  carReviewsRoutes,
  typeArticlesRoutes,
  typeBeforeBuyRoutes,
  researchArticlesRoutes,
  tmvRoute,
  tcoRoute,
  aboutRoutes,
  myAppraiseTradeInRoutes
);
