import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: ['/digital-retail/:vin', '/digital-retail/:vin/:step(payment)'],
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "car_buying_payment" */ '../pages/steps/payment')),
    chunkName: 'car_buying_payment',
    methods: 'get,post',
  },
  {
    path: '/digital-retail/:vin/:step(congratulations)',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "congratulations" */ '../pages/steps/congratulations')),
    chunkName: 'congratulations',
  },
  {
    path: '/digital-retail/:vin/:step(next-steps)',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "next_steps" */ '../pages/steps/next-steps')),
    chunkName: 'next_steps',
    methods: 'get,post',
  },
  {
    path: '/the-edmunds-deal',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car_buying_dr_landing" */ '../pages/digital-retail-landing/landing')
    ),
    chunkName: 'car_buying_dr_landing',
  },
  {
    path: '/car-buying-online',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car_buying_online_landing" */ '../pages/car-buying-online/landing')
    ),
    chunkName: 'car_buying_online_landing',
  },
];
