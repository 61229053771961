import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { UNITED_STATES_LIST } from 'client/data/constants/united-states-list';
import { SELL_CAR } from 'site-modules/editorial/constants/slugs';

export const sellCarRoutes = [
  {
    path: `/${SELL_CAR}/sell-my-car-in-:state(${UNITED_STATES_LIST.join('|')})`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "sell-my-car-in-state" */ '../pages/sell-car/sell-my-car-in-state/sell-my-car-in-state-definition')
    ),
    chunkName: 'sell-my-car-in-state',
  },
  {
    path: `/${SELL_CAR}/sell-my-car-in-:city-:state`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "sell-my-car-in-city-state" */ '../pages/sell-car/sell-my-car-in-city-state/sell-my-car-in-city-state-definition')
    ),
    chunkName: 'sell-my-car-in-city-state',
  },
  {
    path: `/${SELL_CAR}`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "sell-car-landing" */ '../pages/sell-car/sell-car-landing/sell-car-landing-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'sell-car-landing',
  },
  {
    path: `/${SELL_CAR}/index.app`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "sell-car-landing-app" */ '../pages/sell-car/sell-car-landing/sell-car-landing-definition-app'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'sell-car-landing-app',
  },
  {
    path: `/${SELL_CAR}/:splat`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "sell-car-landing" */ '../pages/sell-car/sell-car-landing/sell-car-landing-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => ({
        ...match.params,
      })
    ),
    chunkName: 'sell-car-landing',
  },
];
