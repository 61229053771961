import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/widgets/tables',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "tables" */ '../pages/tables/tables')),
    chunkName: 'tables',
    removeCsp: true,
  },
  {
    path: '/widgets/most-popular-cars',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "most-popular-cars" */ '../pages/most-popular-cars/most-popular-cars')
    ),
    chunkName: 'most-popular-cars',
    removeCsp: true,
  },
  {
    path: '/widgets/depreciation',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "deprecation" */ '../pages/depreciation/depreciation')),
    chunkName: 'depreciation',
    removeCsp: true,
  },
  {
    path: '/widgets/most-sold-type',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "most-sold-type" */ '../pages/most-sold-type/most-sold-type')
    ),
    chunkName: 'most-sold-type',
    removeCsp: true,
  },
  {
    path: '/widgets/most-sold-type/table',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "most-sold-type" */ '../pages/most-sold-type/most-sold-type-table')
    ),
    chunkName: 'most-sold-type',
    removeCsp: true,
  },
  {
    path: '/widgets/most-popular-car-colors',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "most-popular-car-colors" */ '../pages/most-popular-car-colors/most-popular-car-colors')
    ),
    chunkName: 'most-popular-car-colors',
    removeCsp: true,
  },
  {
    path: '/widgets/car-loan-apr-interest-rate',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car-loan-apr-interest-rate" */ '../pages/car-loan-apr-interest-rate/car-loan-apr-interest-rate')
    ),
    chunkName: 'car-loan-apr-interest-rate',
    removeCsp: true,
  },
  {
    path: '/widgets/vehicle',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "vehicle-widgets" */ '../pages/vehicle/vehicle')),
    chunkName: 'vehicle-widgets',
    removeCsp: true,
  },
  {
    path: '/widgets/dealer',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "dealer-widgets" */ '../pages/dealer/dealer')),
    chunkName: 'dealer-widgets',
    removeCsp: true,
  },
  {
    path: '/widgets/ev-range-leaderboard',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "ev-range-leaderboard" */ '../pages/ev-range-leaderboard/ev-range-leaderboard')
    ),
    chunkName: 'ev-range-leaderboard',
    removeCsp: true,
  },
  {
    path: '/widgets/u-drags-leaderboard',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "u-drags-leaderboard" */ '../pages/u-drags-leaderboard/u-drags-leaderboard')
    ),
    chunkName: 'u-drags-leaderboard',
    removeCsp: true,
  },
  {
    path: '/widgets/fast-charging-leaderboard',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "fast-charging-leaderboard" */ '../pages/fast-charging-leaderboard/fast-charging-leaderboard')
    ),
    chunkName: 'fast-charging-leaderboard',
    removeCsp: true,
  },
  {
    path: '/widgets/self-driving-cars',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "self-driving-cars" */ '../pages/self-driving-cars/self-driving-cars')
    ),
    chunkName: 'self-driving-cars',
    removeCsp: true,
  },
  {
    path: '/widgets/leaderboard/:leaderboardId',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "leaderboard" */ '../pages/leaderboard/leaderboard-definition')
    ),
    chunkName: 'leaderboard',
    removeCsp: true,
  },
];
