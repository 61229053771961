/**
 *
 * The list of allowed parameters what used for sending API requests
 *
 * NOTE: keep it consistent with `allowed-seo-srp-request-params.js`
 * Without consistency, newly added parameters will be ignored by purchase
 * funnel SRP calls
 *
 */
// The 'boostDebug' is used for debugging purposes only
export const BOOST_DEBUG = 'boostDebug';
export const CYLINDERS_COUNT = 'cylinders';
export const DEAL_TYPE = 'dealType';
export const DELIVERY_TYPE = 'deliveryType';
export const DISPLAY_PRICE = 'displayPrice';
export const DMA = 'dma';
export const DRIVE_TRAIN = 'driveTrain';
export const EXTERIOR_COLOR = 'exteriorColor';
export const FEATURE = 'feature';
export const COMFORT_CONVENIENCE = 'comfortConvenience';
export const SAFETY = 'safety';
export const ENGINE_EXTERIOR = 'engineExterior';
export const ENTERTAINMENT = 'entertainment';
export const FUEL_TYPE = 'fuelType';
export const HOME_DELIVERY = 'homeDelivery';
export const HISTORY_INFO = 'historyInfo';
export const EXCLUDE_HISTORY_INFO = '-historyInfo';
export const INTERIOR_COLOR = 'interiorColor';
export const INVENTORY_TYPE = 'inventoryType';
export const LATITUDE = 'lat';
export const LEASE_PAYMENT = 'leasePayment';
export const LOAN_PAYMENT = 'loanPayment';
export const LONGITUDE = 'lon';
export const MAKE = 'make';
export const MILEAGE = 'mileage';
export const MODEL = 'model';
export const NATIONAL_INVENTORY = 'national';
export const OPTION = 'option';
export const PAGE_NUMBER = 'pageNum';
export const PAGE_SIZE = 'pageSize';
export const PARENT_DEALERSHIP = 'parentDealership';
export const PAYMENT_TYPE = 'paymentType';
export const PRICE_DROP = 'priceDrop';
export const WITH_PHOTO = 'withPhoto';
export const WITH_PRICE = 'withPrice';
export const EXCLUDE_IN_TRANSIT = 'excludeInTransit';
export const PRODUCT_FEATURE = 'productFeature';
export const RADIUS = 'radius';
export const RECENTLY_LISTINGS = 'recentlyListings';
export const ROOFTOP_ID = 'rooftopId';
export const SESSION_ID = 'sessionId';
export const SORT_BY = 'sortBy';
export const SUBMODEL_ID = 'submodelId';
export const PF_SUBMODEL_ID = 'reviewSubmodelId';
export const TRANSMISSION = 'transmission';
export const TRIM = 'trim';
export const VEHICLE_CATEGORY = 'vehicleCategory';
export const YEAR = 'year';
export const ZIP_CODE = 'zip';
export const COMBINE_MPG = 'combinedMPG';
export const ELECTRICITY_RANGE = 'electricityRange';
export const BATTERY_RANGE_SCORE = 'batteryRangeScore';
export const TRUCK_CAB_SIZE = 'truckCabSize';
export const BED_LENGTH = 'bedLength';
export const DISPLACEMENT = 'displacement';
export const REAR_WHEELS = 'rearWheels';
export const ENGINE_TYPE = 'engineType';
export const BODY_TYPE = 'bodyType';
export const CHALLENGER = 'challenger';
export const STATE_CODE = 'stateCode';
export const CREDIT_PROVIDER = 'creditProvider';
export const EDITORIAL_RATING = 'editorialRating';
export const EDITORIAL_LABEL = 'editorialLabel';
// ---/ This group is only used in FILTER BY sections
export const VIN = 'vin';
export const STOCK_NUMBER = 'stockNumber';
export const FRANCHISE_ID = 'franchiseId';
export const STYLE_ID = 'styleId';
// ---\
export const INITIAL_URL_PATTERN = 'initialUrlPattern';
export const SEO = 'seoInfo';
export const TOTAL_SEATING = 'totalSeating';
export const DELIVERY_COST = 'deliveryCost';

// NOTE: Alphabetical order
export const ALLOWED_INVENTORY_REQUEST_PARAMS = [
  BOOST_DEBUG,
  CREDIT_PROVIDER,
  CYLINDERS_COUNT,
  DEAL_TYPE,
  DELIVERY_TYPE,
  DISPLAY_PRICE,
  DMA,
  DRIVE_TRAIN,
  EXTERIOR_COLOR,
  FEATURE,
  FUEL_TYPE,
  HOME_DELIVERY,
  HISTORY_INFO,
  EXCLUDE_HISTORY_INFO,
  INTERIOR_COLOR,
  INVENTORY_TYPE,
  LATITUDE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  LONGITUDE,
  MAKE,
  MILEAGE,
  MODEL,
  NATIONAL_INVENTORY,
  OPTION,
  PAGE_NUMBER,
  PAGE_SIZE,
  PARENT_DEALERSHIP,
  PAYMENT_TYPE,
  PRICE_DROP,
  PRODUCT_FEATURE,
  RADIUS,
  RECENTLY_LISTINGS,
  ROOFTOP_ID,
  SESSION_ID,
  SORT_BY,
  SUBMODEL_ID,
  PF_SUBMODEL_ID,
  TRANSMISSION,
  TRIM,
  VEHICLE_CATEGORY,
  YEAR,
  ZIP_CODE,
  VIN,
  STOCK_NUMBER,
  FRANCHISE_ID,
  STYLE_ID,
  COMBINE_MPG,
  ELECTRICITY_RANGE,
  TRUCK_CAB_SIZE,
  BED_LENGTH,
  DISPLACEMENT,
  REAR_WHEELS,
  ENGINE_TYPE,
  BODY_TYPE,
  CHALLENGER,
  COMFORT_CONVENIENCE,
  SAFETY,
  ENGINE_EXTERIOR,
  ENTERTAINMENT,
  WITH_PHOTO,
  WITH_PRICE,
  STATE_CODE,
  EXCLUDE_IN_TRANSIT,
  BATTERY_RANGE_SCORE,
  SEO,
  TOTAL_SEATING,
  DELIVERY_COST,
  EDITORIAL_RATING,
  EDITORIAL_LABEL,
];
