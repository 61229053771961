import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

const routes = [
  {
    path: [
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/consumer-reviews`,
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/st-:styleId(\\d+)/consumer-reviews`,
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/consumer-reviews`,
      `/:make(${makes.join('|')})/:model/:year(\\d{4})/:submodel/st-:styleId(\\d+)/consumer-reviews`,
    ],
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "new_model_core_consumer_reviews" */ '../pages/consumer-reviews/consumer-reviews-definition')
    ),
    chunkName: 'new_model_core_consumer_reviews',
  },
];

export { routes };
