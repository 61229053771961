import 'isomorphic-fetch';

import { ClientConfig } from 'client/configuration';
import { IS_NODE, ENVIRONMENT_URL } from 'client/utils/environment';
import { withRetry } from 'client/utils/promise';
import { isClientError, HTTP_NO_CONTENT } from 'client/utils/http-status';
import { apiCache } from 'client/utils/cache-provider';

const DEFAULT_TIMEOUT_MS = 500;
const DEFAULT_RETRIES = 2;
const ABSOLUTE_URL_PATTERN = /^https?:\/\//i;

function addErrorMetadata(error, request, response) {
  if (error) {
    // extract page name and action name from 'x-client-action-name' if available
    if (request.options.headers) {
      const actionName = request.options.headers['x-client-action-name'] || 'unknown';
      error.actionName = actionName; // eslint-disable-line no-param-reassign
      error.pageName = actionName.split('.')[0]; // eslint-disable-line no-param-reassign
    }
    error.apiUrl = response ? response.url : request.url; // eslint-disable-line no-param-reassign

    if (error.name === 'FetchError' && error.type && /timeout$/.test(error.type)) {
      error.status = 408; // eslint-disable-line no-param-reassign
    }
  }

  return error;
}

function wrapResponse(request, response) {
  return {
    ...response,
    status: response.status,
    json() {
      if (response.status === HTTP_NO_CONTENT) {
        return Promise.resolve({}); // added because `response.json()` will throw an error for 204s
      }
      return response.json().catch(error => {
        throw addErrorMetadata(error, request, response);
      });
    },
  };
}

function wrapError(response, errorMessage, responseText, errorCustom) {
  const error = new Error(errorMessage);
  error.status = response.status;
  error.responseText = responseText;
  if (isClientError(error.status)) {
    error.suspendRetries = true;
  }
  error.custom = errorCustom;
  return error;
}

function getOptionsForFetch(fetchOptions, config) {
  if (config.useDeadline) {
    const { headers } = fetchOptions;

    return {
      ...fetchOptions,
      headers: {
        ...headers,
        'x-deadline': Date.now() + fetchOptions.timeout,
      },
    };
  }

  return fetchOptions;
}

/**
 * Returns default error message about failed request.
 *
 * @param url request url.
 * @param status response status.
 * @returns {string} error message.
 */
function getErrorMessage(url, status) {
  return `Request to ${url} failed, got status ${status}`;
}

function fetchWithStatus(url, { showAPIError = false, includeResponseInError = false, ...options }, config = {}) {
  const request = {
    url,
    options,
  };

  return global
    .fetch(url, getOptionsForFetch(options, config))
    .then(response => {
      if (response.ok) {
        return wrapResponse(request, response);
      }

      if (showAPIError) {
        // The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500.
        // Instead, it will resolve normally (with ok status set to false), and it will only reject on network failure
        // or if anything prevented the request from completing.
        // So we need this workaround to get origin API error when request is failed.
        return response
          .json()
          .then(jsonResponse => Promise.reject(wrapError(response, jsonResponse.message, null, jsonResponse)));
      }

      if (includeResponseInError) {
        return response
          .text()
          .then(text => Promise.reject(wrapError(response, getErrorMessage(url, response.status), text)));
      }

      return Promise.reject(wrapError(response, getErrorMessage(url, response.status)));
    })
    .catch(error => {
      throw addErrorMetadata(error, request);
    });
}

function doFetch(url, options, config) {
  const { retries } = options;
  if (IS_NODE) {
    global.logger.debug(`fetch ${url}`);
  }

  return withRetry(fetchWithStatus, retries)(url, options, config);
}

function isCacheEnabled() {
  return IS_NODE && ClientConfig.get('apiCacheEnabled', false);
}

export function json(res) {
  return res.json();
}

class APIClient {
  constructor(baseUrl, config) {
    this.baseUrl = baseUrl;
    this.config = config;
  }

  /**
   * The ClientConfig will only be populated after the first call to ClientConfig.load(),
   * which is currently called in server.js and index[-dev].jsx.
   *
   * @see /server/server.js
   * @see /client/index.jsx
   * @see /client/index-dev.jsx
   * @see /client/configuration.js
   */
  static getDefaultOptions() {
    return {
      timeout: ClientConfig.get('apiDefaultTimeoutMs', DEFAULT_TIMEOUT_MS),
      retries: DEFAULT_RETRIES,
    };
  }

  /**
   * Returns true if API timeout is disabled, false by default
   * Provides developers an option to disable API timeout in local environment in case of slow connection
   *
   * @returns {Boolean}
   */
  static isApiTimeoutDisabled() {
    return ClientConfig.get('apiTimeoutDisabled', false);
  }

  /**
   * Uses fetch API to connect to an API resource.
   *
   * @param {string} resourcePath - relative path to the API resource
   * @param {Object} [options] - request options
   *
   * @returns {Promise}
   */
  fetch(resourcePath, options) {
    // handle lazy load
    const url = typeof this.baseUrl === 'function' ? this.baseUrl(resourcePath) : this.baseUrl;

    const fetchOptions = {
      ...APIClient.getDefaultOptions(),
      ...options,
    };

    if (APIClient.isApiTimeoutDisabled()) {
      // disables timeout in node-fetch
      fetchOptions.timeout = 0;
    }

    return doFetch(`${url}${resourcePath}`, fetchOptions, this.config);
  }

  /**
   * Wrapper function around fetch that simply returns the json response.
   *
   * @param {string} resourcePath - relative path to the API resource
   * @param {Object} [options] - request options
   *
   * @returns JSON response from {Promise}
   */
  fetchJson(resourcePath, options = {}) {
    const cacheEnabled = isCacheEnabled();
    // checking cache regardless of the cache flag in fetch options
    if (cacheEnabled) {
      const response = apiCache.get(resourcePath);
      if (typeof response !== 'undefined') {
        global.logger.debug(`retrieved ${resourcePath} from cache`);
        return Promise.resolve(response);
      }
    }

    return this.fetch(resourcePath, options).then(response => {
      const responseJson = response.json();
      // saving response to the cache depending on options.cache value
      if (cacheEnabled && options.cache === 'force-cache') {
        return responseJson.then(data => {
          apiCache.set(resourcePath, data, options.maxAge || null);
          return data;
        });
      }
      return responseJson;
    });
  }
}

/**
 * @example
 * const config = {
 *   default: '/gateway/api',
 *   vehicle: 'https://localhost:8080/api',
 * };
 * getMatchingBaseUrl(config, '/inventory/v2/') // => '/gateway/api'
 * getMatchingBaseUrl(config, '/vehicle/v2/') // => 'localhost:8080/api'
 */
function getBaseUrl(baseUrls, resourcePath) {
  const apiName = resourcePath.substring(1, resourcePath.indexOf('/', 1));
  return apiName in baseUrls ? baseUrls[apiName] : baseUrls.default;
}

function getFinalApiUrl(clientConfigUrl) {
  return IS_NODE && !ABSOLUTE_URL_PATTERN.test(clientConfigUrl)
    ? `${ENVIRONMENT_URL}${clientConfigUrl}`
    : clientConfigUrl;
}

export const EdmundsAPI = new APIClient(
  resourcePath => {
    const apiUrl = getBaseUrl(ClientConfig.get('edmundsApiUrl'), resourcePath);

    return getFinalApiUrl(apiUrl);
  },
  { useDeadline: true }
);

export const PaymentsCalculatorAPI = new APIClient(() => ClientConfig.get('paymentCalculatorApiUrl'));

export const CarBuyingAPI = new APIClient(() => ClientConfig.get('carBuying').apiUrl);

export const CapOneAPI = new APIClient(() => ClientConfig.get('carBuying').capOneApiUrl);

export const CapOneAPIV2 = new APIClient(() => ClientConfig.get('carBuying').capOneApiUrlBypassApiGateway);

export const UdmAPI = new APIClient(() => ClientConfig.get('udmApiUrl'));

export const CarCodeApi = new APIClient(() => ClientConfig.get('carCodeApiUrl'));

export const IntelligentMessagingAPI = new APIClient(() => ClientConfig.get('intelligentMessagingApiUrl'));

export const idmAPI = new APIClient(() => ClientConfig.get('idmApiUrl'));

export const idmProfilesAPI = new APIClient(() => ClientConfig.get('idmProfilesApiUrl'));

export const idmVanillaForumAPI = new APIClient(() => ClientConfig.get('idmVanillaForumApiUrl'));

export const PopularSearchesAPI = new APIClient(() => ClientConfig.get('popularSearchesApiUrl'));

export const WidgetStoreApi = new APIClient(() => ClientConfig.get('widgetStoreApiUrl'));

export const EvaApi = new APIClient(() => ClientConfig.get('evaApiUrl'));

export const VenomApi = new APIClient(() => ClientConfig.get('venomApiUrl'));

export const MobileRestApiClient = new APIClient(() => ClientConfig.get('mobileRestApiUrl'));

export const CaramelApi = url => new APIClient(() => url);

export const SecurityCredentialsAPI = new APIClient(
  () => ClientConfig.get('awsSecurityCredentialsUrl') + process.env.AWS_CONTAINER_CREDENTIALS_RELATIVE_URI
);

export const EdmundsPartnerOfferAPI = new APIClient(() => getFinalApiUrl(ClientConfig.get('edmundsPartnerOfferApi')));

export const EdmundsPartnerOfferGatewayAPI = new APIClient(() =>
  getFinalApiUrl(ClientConfig.get('edmundsPartnerOfferGatewayApi'))
);

export const EdmundsMultiOfferAPI = new APIClient(() => getFinalApiUrl(ClientConfig.get('edmundsMultiOfferApi')));

export const EdmundsMultiOfferGatewayAPI = new APIClient(() =>
  getFinalApiUrl(ClientConfig.get('edmundsMultiOfferGatewayApi'))
);

export const externalDataAPI = url => new APIClient(() => url);

export const EVInstallAPI = new APIClient(() => getFinalApiUrl(ClientConfig.get('nodeRestCoreApi')));

export const InternalLlmApi = new APIClient(() => getFinalApiUrl(ClientConfig.get('internalLlm')));
