export const MULTI_OFFER_RESULTS = {
  DEFAULT: 'default',
  DEFAULT_HAS_EXPIRED: 'has-expired',
  DEFAULT_ALL_EXPIRED: 'all-expired',
  PENDING: 'pending',
  ONLY_CARMAX_OFFER: 'only-carmax-offer',
  ONLY_CARMAX_EXPIRED_OFFER: 'only-carmax-expired-offer',
};

export const OFFER_DETAILS = {
  DEFAULT: 'default',
  EXPIRED: 'expired',
  ESTIMATED: 'estimated',
  ESTIMATED_EXPIRED: 'estimated_expired',
};
