import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/rss.html',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "rss" */ '../pages/rss/rss-definition')),
    chunkName: 'rss',
  },
];
