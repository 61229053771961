// Add your page url into the list when you want to enable SPA style transition to it
// Please note, the normal string escape rules
// (preceding special characters with \ when included in a string) are necessary.
// For example, '\\w+', '\\d+'
export const spaUrlsProd = [
  // '^/car-comparisons/.*$',
  '^/car-buying-online/$',
  // '^/dealerships/[make]/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/$',
  // '^/dealerships/all/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/$',
  '^/insider/$',
  '^/insider/inventory/$',
  '^/insider/search/$',
  '^/insider/appraisal/$',
  '^/insider/favorite-models/$',
  '^/insider/login-page/$',
  '^/insider/sign-up/$',
  '^/insider/settings/.*$',
  '^/insider/settings/(edit-personal-info)|(email-subscriptions)|(password)/?$',
  '^/insider/sign-up/$',
  '^/digital-retail/[\\w\\d]+/((credit)|(payment)|(next-steps)|(congratulations)|(deal-check))/',
  '^/digital-retail/[A-Za-z0-9_-]+/[A-Za-z0-9_-]+/$',
  '^/digital-retail/[A-Za-z0-9_-]+/$',
  // '^/[type]/articles/[A-Za-z0-9_-]+/$',
  // '^/lease-deals/$',
  // '^/[A-Za-z0-9_-]+-lease-deals/$',
  // '^/used-[A-Za-z0-9_-]+/$',
  // '^/inventory/srp[.]html(.*)$',
  // '^/used-cars-for-sale/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/consumer-reviews/(pg-\\d+/)?$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/[A-Za-z0-9_-]+$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/[A-Za-z0-9_-]+/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/features-specs/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/[A-Za-z0-9_-]+/features-specs/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/review/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/[A-Za-z0-9_-]+/review/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/used/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/[A-Za-z0-9_-]+/used/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/st-[0-9]+-[A-Za-z0-9_-]+/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/trim/$',
  // '^/[make]/[A-Za-z0-9_-]+/\\d+/vin/[A-Za-z0-9_-]+/$',
];
