import { isNil, compact } from 'lodash';
import { getPlural } from 'client/utils/plural';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function objectToQueryString(params) {
  return Object.keys(params)
    .filter(key => !isNil(params[key]))
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}

/**
 * Returns a number string with commas and a certain number of decimal places (if min or max defined)
 * or without them (by default).
 * @param {number} number
 * @param {number} min  minimumFractionDigits
 * @param {number} max  maximumFractionDigits
 * @returns {string} i.e. 12,123 || 123,123 || 1,123,123
 */
export function numberWithCommas(number, min = 0, max = 0) {
  return number.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
}

/**
 * Returns a number string with commas.
 * @param {number} number
 * @returns {string} i.e. 12,123 || 123,123 || 1,123,123
 */
export function numberWithCommasV2(number) {
  return formatter.format(number);
}

/**
 * Replaces all numbers in string with number-with-commas
 */
export function numberWithCommasForString(string) {
  return string && string.replace(/\d+/g, match => numberWithCommas(parseFloat(match)));
}

/**
 * Replaces all numbers in string with number-with-commas-v2
 */
export function numberWithCommasForStringV2(string) {
  return string && string.replace(/\d+/g, match => numberWithCommasV2(parseFloat(match)));
}

/**
 * Returns a number (w/ or w/o commas) with plural text.
 * @param {number} number
 * @param {string} pluralText
 * @param {boolean} [withCommas]
 * @returns {string} i.e. 20,546 miles || 20546 miles
 */
export function numberWithPluralText(number, pluralText, withCommas = false) {
  return `${withCommas ? numberWithCommas(number) : number} ${getPlural(pluralText, number)}`;
}

/**
 * Returns a range string. If min value equals max value then only 1 value returned.
 * @param min
 * @param max
 * @return {string}
 */
export function getRangeString(min, max) {
  return min === max ? min : `${min} - ${max}`;
}

export function stringifyQuotes(string = '') {
  return string.replace(/"/g, '&quot;');
}

export function parseQuotes(string = '') {
  return string.replace(/&quot;/g, '"');
}

/**
 * @param htmlString HTML text with sequence of <p> tags
 * @returns {Array<String>} first element - first paragraph, second element - the rest of string
 */
export function separateFirstParagraph(htmlString) {
  const firstParagraphEnd = htmlString.indexOf('</p>');
  return firstParagraphEnd > -1
    ? [htmlString.substring(0, firstParagraphEnd + 4), htmlString.substring(firstParagraphEnd + 4)]
    : [htmlString];
}

/**
 * Divides the paragraph by count of sentences
 * @param p HTML text inside <p> tags
 * @param count
 * @returns {Array<String>} Array with {count} of strings: first {count} sentences of the paragraph and the rest. <p> tags not included, count by default is 2
 */
export const separateByFirstCountOfSentences = (p, count = 2) => {
  const sentences = p
    .replace(/<p>|<\/p>/g, '')
    .replace(/([.?!])/g, '$1===') // === is a hack to keep the delimiter
    .split('===');

  return [
    sentences
      .slice(0, count)
      .join('')
      .trim(),
    sentences
      .slice(count)
      .join('')
      .trim(),
  ];
};

/**
 * Build string without empty values.
 * E.g.: buildStringValue('test', undefined, 'honda') -> tests honda
 */
export function buildStringValue(...params) {
  return compact(params).join(' ');
}

/**
 * @param string
 * @returns {string} string without special characters
 */
export function removeSpecialCharacters(string = '') {
  return string.replace(/[^a-zA-Z0-9]/g, '');
}

/**
  Removes <a> tags and their attributes but keeps content between them
 * @param {string} str
 * @returns {string}
 */
export function removeLinks(str) {
  return str.replace(/<a\b[^>]*>/gi, '').replace(/<\/a>/gi, '');
}

/** Replace a null characters like \u0000 with empty string
 * @param {string} str
 * @returns {string}
 */
export function replaceNullChars(str) {
  return typeof str === 'string' ? str.replace(/\0/g, '') : str;
}
