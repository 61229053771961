import { PARTNERS } from './partners';

/**
 * Partners, whose offers can be solicited.
 * @type {Array}
 */
export const SOLICITATION_SUPPORTED_PARTNERS = [
  PARTNERS.CARWISER3,
  PARTNERS.AUTONATION,
  PARTNERS.CARMAX,
  PARTNERS.CASHFORCARS,
];

/**
 * Partners, whose offers can be displayed.
 * @type {Array}
 */
export const DISPLAY_SUPPORTED_PARTNERS = [
  {
    partnerToSolicit: PARTNERS.CARWISER,
    partnerKey: PARTNERS.CARWISER,
  },
  {
    partnerToSolicit: PARTNERS.CARWISER3,
    partnerKey: PARTNERS.CARWISER,
  },
  {
    partnerToSolicit: PARTNERS.AUTONATION,
    partnerKey: PARTNERS.AUTONATION,
  },
  {
    partnerToSolicit: PARTNERS.CASHFORCARS,
    partnerKey: PARTNERS.CASHFORCARS,
  },
];

/**
 * Partners, that are supported for WebSocket updates.
 * IMPORTANT NOTE: this array contains partner KEYs (i.e. keys from the 'partners' object of a MODS record).
 *
 * TODO: consider removing this config altogether, and use just solicited partners instead (please see EMO-1539).
 * @type {Array}
 */
export const WEBSOCKET_SUPPORTED_PARTNERS = [PARTNERS.CARWISER, PARTNERS.AUTONATION, PARTNERS.CASHFORCARS];
