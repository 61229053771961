import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  // [0]
  {
    path: '/car-maintenance/guide-page.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "maintenance-guide" */ '../pages/guide-page/guide-page-definition')
    ),
    chunkName: 'maintenance-guide',
  },
  // [1]
  {
    path: '/car-maintenance/results.html',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "maintenance-results" */ '../pages/results/results-definition')
    ),
    chunkName: 'maintenance-results',
  },
];
