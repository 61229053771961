import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: `/:make(${makes.join('|')})/connect`,
    exact: true,
    page: getLoadablePage(
      () =>
        import(/* webpackChunkName: "make_index_connect" */ 'client/site-modules/make/pages/make-connect/make-connect-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      match => match.params
    ),
    chunkName: 'make_index_connect',
  },
];
