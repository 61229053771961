import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const myAppraiseTradeInRoutes = [
  {
    path: '/myappraise-tradein/resources',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "myappraise_tradein_recourses" */ '../pages/myappraise-tradein/resources/resources-definition')
    ),
    chunkName: 'myappraise_tradein_recourses',
  },
];
