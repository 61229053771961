import { makes } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';
import { APPRAISAL_ARTICLES_PATHNAMES } from 'client/site-modules/appraisal/constants/appraisal-articles';

export const routes = [
  {
    path: '/appraisal',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "appraisal" */ '../pages/appraisal/appraisal')),
    chunkName: 'appraisal',
  },
  {
    path: '/appraisal/index.app',
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "appraisal-app" */ '../pages/appraisal/appraisal-app')),
    chunkName: 'appraisal-app',
  },
  {
    path: `/appraisal/:make(${makes.join('|')})-value`,
    exact: true,
    page: getLoadablePage(() => import(/* webpackChunkName: "make_value" */ '../pages/make-value/make-value')),
    chunkName: 'make_value',
  },
  {
    path: Object.values(APPRAISAL_ARTICLES_PATHNAMES),
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "appraisal_article" */ '../pages/appraisal-article/appraisal-article')
    ),
    chunkName: 'appraisal_article',
  },
  {
    path: `/appraisal/history/:make(${makes.join('|')})/:model/:year(\\d{4})`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "appraisal_history_mmy" */ '../pages/appraisal-history/appraisal-history')
    ),
    chunkName: 'appraisal_history_mmy',
  },
  {
    path: `/appraisal/history/:make(${makes.join('|')})/:model/:year(\\d{4})/index.app`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "appraisal_history_mmy_app" */ '../pages/appraisal-history/appraisal-history-app')
    ),
    chunkName: 'appraisal_history_mmy_app',
  },
  {
    path: `/appraisal/history`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "appraisal_history" */ '../pages/appraisal-history/appraisal-history')
    ),
    chunkName: 'appraisal_history',
  },
  {
    path: `/appraisal/history/index.app`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "appraisal_history_app" */ '../pages/appraisal-history/appraisal-history-app')
    ),
    chunkName: 'appraisal_history_app',
  },
  {
    path: '/appraisal/renewal',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "appraisal-renewal" */ '../pages/appraisal-renewal/appraisal-renewal')
    ),
    chunkName: 'appraisal-renewal',
  },
];
